/* eslint-disable */
import * as types from "./graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\nquery cartWavePrices($cart: Cart!, $country: String) {\n  checkout {\n    getPricesForCart(cart: $cart, country: $country) {\n      products {\n        pricePerItem\n        promotionalPricePerItem\n        totalWavePrice\n        line1\n        line2\n        line3\n        totalPrice\n        name\n       currency\n      }\n      selectedProduct\n      productCurrency\n      totalPrice\n      coupon {\n        message\n        price\n        valid\n      }\n    } \n  }\n}\n":
    types.CartWavePricesDocument,
  "\nquery countWaves($spanQuery: WaveSpansQuery!) {\n  waves {\n    getWavePeriods(spanQuery: $spanQuery) {\n      startTime\n      endTime\n      count\n    } \n  }\n}\n":
    types.CountWavesDocument,
  "\nquery getWaves($dt: DateTime!, $location: String!){\n  waves {\n    getWaves(location: $location, selectedDate: $dt) {\n      id,gif, site,createdAt,img,videoLength\n    }\n  }\n}":
    types.GetWavesDocument,
  "\nmutation buy($cart: Cart!, $payId: String, country: String) {\n  buy {\n    checkout(cart: $cart, payId: $payId, country: $country) {\n      message,\n      success\n    }\n  }\n}\n":
    types.BuyDocument,
  "\nmutation track($info: String){\n  track {\n    track(info: $info)\n  }\n}":
    types.TrackDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\nquery countWaves($spanQuery: WaveSpansQuery!) {\n  waves {\n    getWavePeriods(spanQuery: $spanQuery) {\n      startTime\n      endTime\n      count\n    } \n  }\n}\n",
): (typeof documents)["\nquery countWaves($spanQuery: WaveSpansQuery!) {\n  waves {\n    getWavePeriods(spanQuery: $spanQuery) {\n      startTime\n      endTime\n      count\n    } \n  }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\nquery cartWavePrices($cart: Cart!, $country: String) {\n  checkout {\n    getPricesForCart(cart: $cart, country: $country) {\n      products {\n        pricePerItem\n        promotionalPricePerItem\n        totalWavePrice\n        line1\n        line2\n        line3\n        totalPrice\n        name\n       currency\n      }\n      selectedProduct\n      productCurrency\n      totalPrice\n      coupon {\n        message\n        price\n        valid\n      }\n    } \n  }\n}\n",
): (typeof documents)["\nquery cartWavePrices($cart: Cart!, $country: String) {\n  checkout {\n    getPricesForCart(cart: $cart, country: $country) {\n      products {\n        pricePerItem\n        promotionalPricePerItem\n        totalWavePrice\n        line1\n        line2\n        line3\n        totalPrice\n        name\n       currency\n      }\n      selectedProduct\n      productCurrency\n      totalPrice\n      coupon {\n        message\n        price\n        valid\n      }\n    } \n  }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\nquery getWaves($dt: DateTime!, $location: String!){\n  waves {\n    getWaves(location: $location, selectedDate: $dt) {\n      id,gif, site,createdAt,img,videoLength\n    }\n  }\n}",
): (typeof documents)["\nquery getWaves($dt: DateTime!, $location: String!){\n  waves {\n    getWaves(location: $location, selectedDate: $dt) {\n      id,gif, site,createdAt,img,videoLength\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\nmutation track($info: String){\n  track {\n    track(info: $info)\n  }\n}",
): (typeof documents)["\nmutation track($info: String){\n  track {\n    track(info: $info)\n  }\n}"];

export function graphql(
  source: "\nmutation buy($cart: Cart!, $payId: String, country: String) {\n  buy {\n    checkout(cart: $cart, payId: $payId, country: $country) {\n      message,\n      success\n    }\n  }\n}\n",
): (typeof documents)["\nmutation buy($cart: Cart!, $payId: String, country: String) {\n  buy {\n    checkout(cart: $cart, payId: $payId, country: $country) {\n      message,\n      success\n    }\n  }\n}\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
