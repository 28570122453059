import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsPlayCircle } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { endpoints } from "../../../Api/Api";
import Controller from "../../Controller/Controller";
import "./MySession.styles.scss";
import SubsPopup from "../../Modal/subsPopup";
import DayPassPopup from "../../Modal/dayPass";
import DayPassWarn from "../../Modal/dayPassWarn";
import { RotatingLines } from "react-loader-spinner";
import { inBrazilRegion } from "../../../utils/region";

const SessionItem = ({
  index,
  // video,
  date,
  location,
  id,
  user_id,
  coachguidence,
  gif,
  image,
}) => {
  const navigate = useNavigate();
  function handleDateFormat() {
    let x = date.slice(0, 10);
    let year = x.slice(0, 4);
    let month = x.slice(5, 7);
    let day = x.slice(8, 10);
    return `${day}-${month}-${year}`;
  }
  return (
    <div className="mysession__contents-item">
      <div className="mysession__contents-item-date">{handleDateFormat()}</div>
      <div className="mysession__contents-item-location">{`${location}`}</div>
      {/* <Link to={`/session?view=player&wave=${id}`}> */}
      <div
        className="mysession__contents-item-video"
        onClick={() =>
          navigate(`/mysession/player/${id}`, {
            state: {
              date: handleDateFormat(),
              location: location,
            },
          })
        }
      >
        {image ? (
          <img src={image} alt="" width="100%" height="100%" />
        ) : (
          <BsPlayCircle />
        )}
      </div>
      {/* </Link> */}
      <div className="mysession__contents-item-session-title">{`${inBrazilRegion ? "Sessão" : "Session"} # ${index}`}</div>
      {/* <div className="mysession__contents-item-buttons"> */}
      <button
        className="mysession__contents-item-buttons-button"
        onClick={() =>
          navigate(`/mysession/player/${id}`, {
            state: {
              date: handleDateFormat(),
              location: location,
            },
          })
        }
      >
        {inBrazilRegion ? "Assista" : "Watch"}
      </button>
      {/* <button
          className="mysession__contents-item-buttons-button"
          // style={{ cursor: coachguidence === true ? "no-drop" : "pointer" }}
          onClick={() =>
            // coachguidence === false &&
            navigate(`/session?view=payments`, {
              id: id,
              price: 35,
              title: "1 coaching session",
            })
          }
        >
          Get coaching
        </button> */}
      {/* </div> */}
    </div>
  );
};

const MySession = () => {
  const [mySession, setMySession] = useState([]);
  const token = localStorage.getItem("token");
  const filterDate = useSelector((state) => state.filter);

  const passTypeFromLocal = localStorage.getItem("passType");
  const [passType, setPasstype] = useState(passTypeFromLocal);
  const [isLoading, setIsLoading] = useState(true);

  const allpopUpHandler = () => {
    setPasstype(null);
    localStorage.removeItem("passType");
  };

  useEffect(() => {
    axios
      .get(endpoints.MY_SESSIONS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMySession(res?.data?.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="60"
        visible={true}
      />
    );
  }

  if (mySession === undefined || mySession?.length === 0) {
    return (
      <div className="SessionText">
        {inBrazilRegion
          ? "Nenhuma sessão para assistir"
          : "No Sessions To Watch"}
      </div>
    );
  }

  return (
    <>
      <div className="mysession">
        <div className="mysession-container">
          <div className="mysession-controller" hidden="hidden">
            <Controller isMysession={true} />
          </div>
          <div className="mysession__contents scrollbar">
            {mySession?.length === 0 || mySession === undefined ? (
              // <div style={{ fontSize: "30px", marginTop: "24px" }}>
              //   No Sessions To Watch
              // </div>
              <></>
            ) : (
              mySession
                .filter((item) => {
                  if (item?.date?.slice(0, 16) === filterDate) {
                    return item;
                  } else if (filterDate === null) {
                    return item;
                  }
                })
                .map((e, i) => (
                  <>
                    <div key={i}>
                      <SessionItem
                        index={++i}
                        date={e.date}
                        coachguidence={e.coachguidence}
                        id={e.id}
                        location={e.location}
                        user_id={e.user_id}
                        gif={e.gif}
                        image={e.img}
                      />
                    </div>
                  </>
                ))
            )}
          </div>
        </div>
      </div>
      {passType ? (
        passType !== "month" ? (
          passType === "lastday" ? (
            <DayPassWarn handleClick={allpopUpHandler} />
          ) : (
            <DayPassPopup handleClick={allpopUpHandler} />
          )
        ) : (
          <SubsPopup handleClick={allpopUpHandler} />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default MySession;
