import React, { ReactNode, useEffect, useState } from "react";
import { useWaveContext } from "../../../context/CartContext";
import styles from "./Checkout.module.css";
import { useActor } from "@xstate/react";
import { Token } from "react-stripe-checkout";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FAQ } from "../../Modal/faq";
import axios from "axios";

import { loadStripe } from "@stripe/stripe-js";
import { endpoints } from "../../../Api/Api";
import dayjs from "dayjs";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { NewCheckout } from "./NewCheckout/NewCheckout";
import { PLAN_TYPES, PlanType } from "./constants";
import { inBrazilRegion } from "../../../utils/region";
import { OnExpressClick } from "./NewCheckout/NewCheckoutFooter";

const logInfo = (...args: any[]) => {
  if (process.env.NODE_ENV !== "production") {
    return console.log(args);
  }
};

const VideoModal = ({ video, setVideo }) => {
  return (
    <div className={styles.videomodal} onClick={() => setVideo(false)}>
      <div
        className={styles.videomodal_video}
        onClick={(e) => e.stopPropagation()}
      >
        <video
          src="https://surfeye-web.s3.eu-central-1.amazonaws.com/spots/nova-praia/highlights/session-preview/SESSION+VIDEO+PREVIEW.mp4"
          preload="auto"
          width="100%"
          height="100%"
          muted
          loop
          autoPlay
          controls
        ></video>
      </div>
    </div>
  );
};

interface PayItemProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  name?: string;
  footer?: ReactNode;
  onClick: () => void;
  selected: boolean;
}

// "Day Pass"
const PayItem = ({
  name,
  footer,
  children,
  onClick,
  selected,
  ...rest
}: PayItemProps) => {
  const [isHovered, setHovered] = useState(selected);
  useEffect(() => setHovered(selected), [selected]);
  return (
    <div {...rest} className={styles.wrapper}>
      <div
        className={`${styles.BorderPx} ${isHovered && styles["payItemFooter-select"]}`}
      >
        <div>
          <div className={`${styles.payItemTop}`}>{children}</div>
          <div className={`${styles.payItemFooter}`}>{footer}</div>
        </div>
        <div
          className={styles.shim}
          onClick={() => {
            if (onClick != undefined) {
              onClick();
            }
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false || selected)}
        ></div>
      </div>
      <div className={`${styles.borderNone}`}>
        {name == "per-day" && selected ? (
          <p>
            MAKE SURE TO SELECT ALL THE WAVES YOU WANT TO DOWNLOAD BEFORE
            BUYING. YOU WILL NOT BE ABLE TO SELECT MORE LATER.
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const PopupData = () => {
  return (
    <div className={styles.checkoutpop}>
      <div className={styles.checkoutpop_text}>
        Surf Eye coaching is a premium service that costs €35 per session (VAT
        included). <br></br> A coaching session allows you to get feedback from
        a professional coach and includes
      </div>
      <ul className={styles.checkoutpop_list}>
        <li className="checkoutpop-list-item">
          {" "}
          <li className="checkoutpop-list-item">
            30 minutes video call with professional coach
          </li>
          <li className="checkoutpop-list-item">
            Wave by wave feedback and corrections
          </li>
          <li className="checkoutpop-list-item">
            Tips both for inland and at sea exercises to improve your surfing
          </li>
        </li>
      </ul>
      <div className={styles.checkoutpop_text}>
        After payment is completed, the videos of your session are automatically
        shared with <br></br> one of our coaches, who will contact you within 2
        business days to schedule a video call.
      </div>
    </div>
  );
};

const QuestionMark = () => {
  return <div className={styles["checkout-popup-question"]}>?</div>;
};

const AvailableDatesFormat = () => {
  const dates: any = [];
  const eodToday = dayjs.utc();
  dates.push({
    startTime: dayjs(eodToday).format("YYYYMMDD"),
  });
  for (let i = 1; i < 8; i++) {
    const tmDate = eodToday.subtract(i, "day");
    dates.push({
      startTime: tmDate.format("YYYYMMDD"),
    });
  }
  return dates;
};

export const Checkout2 = ({ ...props }) => {
  // const location = useLocation();
  // const [current, send] = useMachine(watchAndBuySM);
  const [video, setVideo] = useState(false);
  const { waveService } = useWaveContext();
  const [current] = useActor(waveService);
  const navigate = useNavigate();
  const [faqHandler, setFaqHandler] = useState(false);

  const [planType, setPlanType] = useState<PlanType>(PLAN_TYPES.wavePass);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);

  useEffect(() => {
    if (inBrazilRegion) {
      setPlanType(PLAN_TYPES.monthPass);
    }
    waveService.send({
      type: "reset",
    });
    const wavesFromLocal = JSON.parse(localStorage.getItem("waves"));
    for (let key in wavesFromLocal) {
      const wave = wavesFromLocal[key];
      waveService.send({
        type: "updateWaves",
        data: wave,
      });
    }
    const packageSelected = localStorage.getItem("packageSelected");
    setTimeout(() => {
      logInfo("[useEffect -> setTimeout]::[current]::", current.context);
      if (packageSelected === "month") {
        waveService.send({
          type: "selectProduct",
          product: current.context.products[2],
        });
        setPlanType(PLAN_TYPES.monthPass);
        localStorage.removeItem("packageSelected");
      }
    }, 1);
  }, []);

  useEffect(() => {
    if (current.matches("end")) {
      // Swal.fire(current.context.message, "", "success");
      // set hint for popup
      if (planType === "day") {
        const waveDate = current?.context?.cart?.waves[0].createdAt;
        const waveDayFormat = dayjs(waveDate).format("YYYYMMDD");
        const availDates = AvailableDatesFormat();
        const lastAvaildayForWave = availDates[7]?.startTime;
        if (waveDayFormat === lastAvaildayForWave) {
          localStorage.setItem("passType", "lastday");
        } else {
          localStorage.setItem("passType", "day");
        }
      } else {
        // Swal.fire(current.context.message, "", "success")
      }
      // when waves successfully buyed then reset cart as well as localstorage waves data
      waveService.send({
        type: "reset",
      });
      setIsLoadingCheckout(false);
      localStorage.removeItem("waves");
      localStorage.removeItem("location");
      localStorage.removeItem("date");
      navigate("/mysession");
    }
  }, [current]);

  // useEffect(() => {
  //   if (current.matches('waves')) {
  //     navigate("/session");
  //   }
  // }, []);

  // const history = useHistory();
  // const { waves, updateWaves, coaching, setCoaching } = useCart();

  const checkHandlerBeforeCheckout = () => {
    const data = current.context.cart.waves;
    // const data = localStorage.getItem("waves")
    setIsLoadingCheckout(true);
    if (!data || data.length <= 0) {
      setIsLoadingCheckout(false);
      return;
    }
    const customertoken = localStorage.getItem("token");
    axios
      .post(
        endpoints.CREATE_SESION_FOR_ACTIVE_SUBSCRIPTION,
        {
          data: data,
        },
        {
          headers: {
            Authorization: `Bearer ${customertoken}`,
          },
        },
      )
      .then((response) => {
        if (response.data.status === "True") {
          setIsLoadingCheckout(false);
          // Swal.fire(response.data.message, "", "success");
          waveService.send({
            type: "reset",
          });
          // pay with wallet for subscription
          localStorage.setItem("passType", "month");
          var date = new Date(); // Now
          const ddd = date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
          const cnvtD = dayjs(ddd).format("YYYYMMDD");
          localStorage.setItem("subsDate", cnvtD);

          localStorage.removeItem("date");
          localStorage.removeItem("waves");
          localStorage.removeItem("location");

          // Swal.fire(response.data.message, "", "success");
          navigate("/mysession");
        } else {
          setIsLoadingCheckout(false);
          // Swal.fire(response.data.message, "", "error");
        }
      })
      .catch((error) => {
        setIsLoadingCheckout(false);
        logInfo(error);
      });
  };

  useEffect(() => {
    checkHandlerBeforeCheckout();
  }, []);

  const handleCreateSesssion = () => {
    const data = current.context.cart.waves;
    setIsLoadingCheckout(true);
    if (data.length <= 0) {
      setIsLoadingCheckout(false);
      return;
    }
    const customertoken = localStorage.getItem("token");
    axios
      .post(
        endpoints.CREATE_SESION_FOR_ACTIVE_SUBSCRIPTION,
        {
          data: data,
        },
        {
          headers: {
            Authorization: `Bearer ${customertoken}`,
          },
        },
      )
      .then((response) => {
        if (response.data.status === "True") {
          setIsLoadingCheckout(false);
          // Swal.fire(response.data.message, "", "success");
          waveService.send({
            type: "reset",
          });
          if (planType === "month") {
            localStorage.setItem("passType", "month");
            var date = new Date(); // Now
            const ddd = date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
            const cnvtD = dayjs(ddd).format("YYYYMMDD");
            localStorage.setItem("subsDate", cnvtD);
          }
          localStorage.removeItem("date");
          localStorage.removeItem("waves");
          localStorage.removeItem("location");

          // Swal.fire(response.data.message, "", "success");
          navigate("/mysession");
        } else {
          setIsLoadingCheckout(false);
          Swal.fire(response.data.message, "", "error");
        }
      })
      .catch((error) => {
        setIsLoadingCheckout(false);
        logInfo(error);
      });
  };

  const handleForPayout = (token: Token) => {
    setIsLoadingCheckout(true);
    const plan = current.context?.selectedProduct;
    const customertoken = localStorage.getItem("token");

    if (plan.name === "per-month") {
      axios
        .post(
          endpoints.SUBSCRIPTION_CREATE,
          {
            // payid: token.id,
            // day: `${day}-${moment.utc(new Date()).format("YYYYMMDDHHmmss")}-${moment.utc(new Date(new Date().getTime() + 60 * 60 * 24 * 1000)).format("YYYYMMDDHHmmss")}`,
            plan: planType,
            country: inBrazilRegion ? "BR" : "PT",
          },
          {
            headers: {
              Authorization: `Bearer ${customertoken}`,
            },
          },
        )
        .then((response) => {
          if (response.data.status == "True") {
            const afPayment = async () => {
              const stripe = await loadStripe(
                process.env.REACT_APP_STRIPE_CLIENT_ID,
              );
              stripe
                ?.confirmCardPayment(response.data.clientSecret, {
                  payment_method: {
                    card: {
                      token: token.id,
                    },
                  },
                })
                .then(function (result) {
                  // Handle result.error or result.paymentIntent
                  if (result.error) {
                    Swal.fire("Payment Unsuccessfull", "", "error");
                  } else {
                    // api call for save subscription id {UPDATESUBSCRIPTION}
                    axios
                      .post(
                        endpoints.INSERT_SUBSCRIPTION,
                        {
                          subsId: response.data.sub,
                          wave: current?.context?.cart?.waves[0],
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${customertoken}`,
                          },
                        },
                      )
                      .then((response) => {
                        handleCreateSesssion();
                      })
                      .catch((err) => {
                        setIsLoadingCheckout(false);
                        logInfo(err, "payment not updated");
                      });
                  }
                })
                .catch((err) => {
                  setIsLoadingCheckout(false);
                  logInfo(err, "payment error");
                });
            };
            afPayment();
            // navigate.push("/my-ride")
          } else {
            setIsLoadingCheckout(false);
            Swal.fire(response.data.message, "", "error");
          }
        });
    } else {
      // create charge by previous flow (using context api)
      waveService.send({
        type: "buy",
        payId: token.id,
      });
    }
  };

  const handleClick = (bool: boolean) => {
    setFaqHandler(bool);
  };

  const handleForNoCostSession = () => {
    setIsLoadingCheckout(true);
    waveService.send({
      type: "buy",
      payId: "NOCOST",
    });
    // const data = current.context.cart.waves
    // setIsLoadingCheckout(true);
    // if(data.length <= 0){
    //   setIsLoadingCheckout(false);
    //   return
    // }
    // const customertoken = localStorage.getItem("token");
    //   axios
    //     .post(
    //       endpoints.NOCOST_CREATE_SESSION,
    //       {
    //         data: data,
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${customertoken}`,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       if (response.data.status === "True") {
    //         setIsLoadingCheckout(false);
    //         // Swal.fire(response.data.message, "", "success");
    //         waveService.send({
    //           type: 'reset'
    //         });
    //         localStorage.removeItem("date")
    //         localStorage.removeItem("waves")
    //         localStorage.removeItem("location")

    //         // Swal.fire(response.data.message, "", "success");
    //         navigate("/mysession")
    //       } else {
    //         setIsLoadingCheckout(false);
    //         Swal.fire(response.data.message, "", "error");
    //       }
    //     })
    //     .catch((error) => {
    //       setIsLoadingCheckout(false);
    //       logInfo(error)
    //     });
  };

  const stripe = useStripe();
  const elements: any = useElements();
  const onConfirm = async () => {
    const customertoken = localStorage.getItem("token");
    const dateLC = localStorage.getItem("date");
    const plan = planType;

    if (!stripe) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error: backendError, clientSecret } = await fetch(
      `${endpoints.PAYMENT_INTENT}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + customertoken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // paymentMethodType: 'card',
          currency: "eur",
          amount: `${current.context?.totalPrice * 100}`,
        }),
      },
    ).then((r) => r.json());

    // Confirm the PaymentIntent using the details collected by the Express Checkout Element
    const res = await stripe.confirmPayment({
      // `elements` instance used to create the Express Checkout Element
      elements,
      // `clientSecret` from the created PaymentIntent
      clientSecret,
      confirmParams: {
        // editable
        return_url: `http://localhost:3000/mysession`,
      },
      redirect: "if_required",
    });
    if (res.error) {
      logInfo("error", res.error);
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      // setErrorMessage(error.message);
    } else {
      // create session logic
      setIsLoadingCheckout(true);
      waveService.send({
        type: "buy",
        payId: "BYWALLET",
      });
    }
  };

  const onClick = ({ resolve }: OnExpressClick) => {
    const options = {
      emailRequired: true,
      wallet: "always",
    };
    resolve(options);
  };

  useEffect(() => {
    props.handleForPriceAmount(
      Number(current.context?.totalPrice?.toFixed(2)) * 100 || 9.99 * 100,
    );
  }, [current.context.totalPrice]);

  const handleForCreateSubscription = () => {
    const customertoken = localStorage.getItem("token");
    if (planType === "month") {
      axios
        .post(
          endpoints.LINK_FOR_PAY,
          {},
          {
            headers: {
              Authorization: `Bearer ${customertoken}`,
            },
          },
        )
        .then((response) => {
          if (response.data.status == "True") {
            const payLink = response.data.url;
            window.location.href = payLink;
          }
        })
        .catch((err) => {
          logInfo(err.message);
        });
    }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <>
      <NewCheckout
        onNavigateBack={handleNavigateBack}
        handleFreeCheckout={handleForNoCostSession}
        handlePaidCheckout={handleForPayout}
        isCheckoutPending={isLoadingCheckout}
        setPlanType={setPlanType}
        onExpressClick={onClick}
        onExpressConfirm={onConfirm}
      />

      {faqHandler ? <FAQ handleClick={handleClick} /> : <></>}
    </>
  );
};
