import styles from "../Session/Checkout/Checkout.module.css";

import LocationVIew from "../../../src/assets/LandingNew/LocationVIew.svg";
import streamingLive from "../../../src/assets/LandingNew/streamingLive.svg";
import { useNavigate } from "react-router-dom";
import { inBrazilRegion } from "../../utils/region";

export const LocationArea = ({ locationDetails, closeHandler }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.locationArea}>
        <div className={styles.background}></div>
        <div className={styles.locationAreaModal}>
          <button
            className={styles.closeFaq}
            onClick={() => closeHandler(false)}
          >
            X
          </button>

          <div className={styles.locationBox}>
            <div className={styles.locationImg}>
              <img src={locationDetails?.imgLocation} alt="" />
            </div>

            <div className={styles.boxContent}>
              <h3>{locationDetails?.title1}</h3>
              <h4>{locationDetails?.title2}</h4>
              <h5>
                {inBrazilRegion
                  ? "Do nascer ao pôr do sol"
                  : "From sunrise to sunset"}
              </h5>

              <div className={styles.BoxMain}>
                <div className={styles.box}>
                  <img src={streamingLive} alt="" />
                  {inBrazilRegion ? (
                    <h6>
                      Ao <br></br> vivo
                    </h6>
                  ) : (
                    <h6>
                      Live <br></br> streaming
                    </h6>
                  )}
                  <button
                    onClick={() =>
                      navigate(`/beachcam/${locationDetails.slug}`)
                    }
                  >
                    {inBrazilRegion ? "Assistir" : "Go Now"}
                  </button>
                </div>
                <div className={styles.box}>
                  <img src={LocationVIew} alt="" />
                  {inBrazilRegion ? (
                    <h6>
                      Obter <br></br> direreções
                    </h6>
                  ) : (
                    <h6>
                      get <br></br> directions
                    </h6>
                  )}
                  <button
                    onClick={() => {
                      if (locationDetails?.googleLocationLink) {
                        // window.location.href= locationDetails.googleLocationLink
                        window.open(locationDetails.googleLocationLink);
                      }
                    }}
                  >
                    {inBrazilRegion ? "Ir ao pico" : "go now"}
                  </button>
                </div>
              </div>

              <div className={styles.bottomImg}>
                <img src={locationDetails?.imgSponsor} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
