import React from "react";
import styles from "./NewProfile.module.css";
import { inBrazilRegion } from "../../../../utils/region";

type NewProfileProps = {
  subscriptionInfo: {
    passType: string;
    renewD: Date;
  };
  showCancelSubscriptionPopup: () => void;
  dueDate: string;
};

export const NewProfile = ({
  subscriptionInfo,
  showCancelSubscriptionPopup,
  dueDate,
}: NewProfileProps) => {
  return (
    <div className={styles.profileContainer}>
      {subscriptionInfo.passType !== "nb" ? (
        <div className={styles.activeProfileInnerContainer}>
          <div className={`${styles.profileBox} ${styles.big}`}>
            <span className={styles.label}>
              {inBrazilRegion ? "Subscrição" : "Subscription"}
            </span>
            <span className={`${styles.value} ${styles.active}`}>
              Surf Eye <span className={styles.highlighted}>{inBrazilRegion ? "Ilimitado" : "Unlimited"}</span>{" "}
            </span>
          </div>

          <div className={`${styles.details} ${styles.vstack}`}>
            <div className={styles.profileBox}>
              <span className={`${styles.label} ${styles.vstack}`}>
                <span className={`${styles.small} ${styles.capitalize}`}>
                  {inBrazilRegion ? "Renovação Automática" : "Auto Renewal"}
                </span>
                <span className={`${styles.medium} ${styles.uppercase}`}>
                  {subscriptionInfo.passType === "recurr" ? "On" : "Off"}
                </span>
              </span>
              <span className={`${styles.value}`}>
                {subscriptionInfo.passType === "recurr" ? (
                  <button
                    className={styles.unsubscribeButton}
                    onClick={showCancelSubscriptionPopup}
                  >
                    {inBrazilRegion ? "Cancelar inscrição" : "Unsubscribe"}
                  </button>
                ) : null}
              </span>
            </div>
            <div className={styles.profileBox}>
              <span className={`${styles.label} ${styles.vstack}`}>
                <span className={`${styles.small} ${styles.capitalize}`}>
                  {subscriptionInfo.passType === "recurr"
                    ? inBrazilRegion
                      ? "Próximo pagamento"
                      : "Next Payment"
                    : inBrazilRegion
                      ? "Data de validade"
                      : "Expiry Date"}
                </span>
                <span className={`${styles.medium} ${styles.uppercase}`}>
                  {dueDate}
                </span>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className={`${styles.profileBox} ${styles.big}`}>
          <span className={styles.label}>
            {inBrazilRegion ? "Subscrição" : "Subscription"}
          </span>
          <span className={styles.value}>
            {inBrazilRegion
              ? "Nenhuma assinatura ativa"
              : "No active subscription"}
          </span>
        </div>
      )}
    </div>
  );
};
