import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import LazyLoad from "react-lazy-load";
import { WaveInfo } from "./Watch2";
import styles from "./WaveList.module.css";
import { InView, useInView } from 'react-intersection-observer';
import { useTrack } from "../../../services/track";
import { useWaveContext } from "../../../context/CartContext";
import { useActor } from "@xstate/react";
import { inBrazilRegion } from '../../../utils/region';



const WatchVideo = ({ id, onChange, date, selected, video, site }) => {
  const timestamp = dayjs(date).format('HH:mm')
  const videoRef = useRef();
  const { ref, inView, entry } = useInView({
    threshold: 0.2,
  });
  return (<div ref={ref}
    onContextMenu={(e) => {
      e.preventDefault();
      return false;
    }}

    className={`${styles["WatchItem-item"]} ${selected && styles.selected}`}
    style={{ paddingBottom: '30px' }}
    onClick={onChange}>
    {inView ?
      (
        <video
          // controls preload="none"
          // controlsList="play timeline nodownload nofullscreen "
          // disablePictureInPicture
          playsInline
          loop
          width="100%"
          height="100%"
          ref={videoRef}
          autoPlay
          src={video}></video>

      )
      :
      (
        <div style={{
          // height: '230px',
          backgroundColor: 'black '
        }} />
      )
    }
    <div
      // className="watch__items-item-checkbox"
      className={styles["WatchItem-item-checkbox-container"]}
    >
      <caption>{timestamp}</caption>
      <div className={styles["checkBox"]}>
        <input onChange={onChange} type="checkbox" id={id} value={site} checked={selected} />
        <span className={styles.checkmark}></span>
      </div>
    </div>

  </div>)
}

type TimeHeaderProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  onVisible?: () => void,
  sRef?: React.MutableRefObject<undefined>
}

const TimeHeader = (props: TimeHeaderProps) => {
  const pAny2 = props as any;
  const { ref, onVisible, ...pAny } = pAny2;
  const { ref: inViewRef, inView } = useInView();

  // Use `useCallback` so we don't recreate the function on each render
  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      if (props.sRef != undefined) {
        props.sRef.current = node;
      }
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node);
    },
    [inViewRef],
  );
  useEffect(() => {
    if (inView) {
      if (onVisible != undefined) {
        onVisible();
      }
    }
  }, [inView]);

  return <div ref={setRefs} {...pAny}></div>;
}

type Params = {
  waves: Array<WaveInfo>;
  selectedWaves: Array<WaveInfo>;
  sccrollTime?: Date;
  onChange?: (wave: Partial<WaveInfo>) => void;
  onInitialScroll: () => void;
  onTrack?: (info: String) => void;
}

type WaveGroup = {
  startIdx: number,
  endIdx: number,
  startDate: Date,
  endDate: Date
}


export const CartWaveList = ({ waves, sccrollTime, selectedWaves, onChange, onInitialScroll }: Params) => {
  const { waveService } = useWaveContext();
  const [current] = useActor(waveService);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [waves]);

  


  return (
    <div className={styles.WaveContainer} onScroll={() => onInitialScroll()}>
      {[0].map((wg, groupidx) => {
        const wavesIR = [];
        for (let key in waves) {
          const item = waves[key];
          const selected = selectedWaves.find((sw) => sw.id == item.id) != undefined;
          // selected waves filter from localstorage
          wavesIR.push(
            <WatchVideo
              onChange={() => {
                if (onChange != undefined) {
                  onChange(item);
                }
              }}
              selected={selected}
              video={`/${item?.gif}`}
              id={item?.id}
              site={item?.site}
              date={item?.createdAt}
            />
          )
        }
        return (
          <>
            <TimeHeader 
                style={{
                fontSize: '1.5rem',
                fontWeight: '700',
                fontStyle: 'italic',
                backgroundColor: '#59A4EB',
                color: '#FFF',
                margin: '1rem 0 1rem 0',
                borderRadius: '2px',
                padding: '1rem 0 1rem 0',
                textAlign: 'center'

              }}>{inBrazilRegion ? "ONDAS SELECIONADAS" : 'WAVES SELECTED'}</TimeHeader>
            <div className={styles.WaveItemGrid}>
              {wavesIR}
            </div>
          </>
        )
      })}
    </div>
  );
  
}