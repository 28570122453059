// 'use client';
import React from "react";

import { SectionLayout } from "../../../layouts/section";
import { FeatureCard } from "./feature-card";
import { features } from "./features";
import { Carousel, CarouselItem } from "../../../components/SitePage/carousel";
import { GenericButton } from "../../../components/SitePage/button";
import { useMediaQuery } from "../../../hooks/use-media-query";
import { Link } from "react-router-dom";
import { inBrazilRegion } from "../../../utils/region";

export const FeaturesSection = ({ spot }) => {
  const isHDDevice = useMediaQuery("only screen and (min-width : 1280px)");
  return (
    <SectionLayout className="pb-10 px-0 md:px-0 lg:px-0 space-y-3.5">
      <div className="flex items-center gap-3.5 justify-between px-4 md:px-6 lg:px-4 xl:px-0">
        <Link to="/?section=login">
          <h2 className="uppercase text-brand-md md:text-lg lg:text-2xl font-bold">
            {inBrazilRegion ? "OBTENHA SURF EYE" : "Get surf eye"} <span className="text-brand-yellow">unlimited</span>
          </h2>
        </Link>
        <Link to="/?section=login">
          <GenericButton variant="yellow" small>
            {inBrazilRegion ? "COMECE AGORA" : "Get Started"}
          </GenericButton>
        </Link>
      </div>

      <Carousel className="py-3 px-5 gap-4" hideControls={isHDDevice}>
        {features.map(({ title, description, icon: Icon }) => (
          <CarouselItem key={title} className="last:pr-5">
            <FeatureCard>
              <div className="space-y-1 flex-1">
                <p className="uppercase font-bold text-xs md:text-brand-md">
                  {title}
                </p>
                <p className="font-medium text-xs md:text-brand-md">
                  {description}
                </p>
              </div>
              <div className="w-10 md:w-14">
                <Icon className="w-full" />
              </div>
            </FeatureCard>
          </CarouselItem>
        ))}
      </Carousel>
    </SectionLayout>
  );
};
