// 'use client';
import React, { useEffect } from "react";
import { ForecastDayData } from "../types";
import { ForecastChart } from "../chart";
import { ForecastDayMetrics } from "./metrics";
import { inBrazilRegion } from '../../../../utils/region';

type ForecastChartProps = {
  dataSet: ForecastDayData;
};

export const ForecastDay = ({ dataSet }) => {
  useEffect(() => {}, [dataSet]);

  return (
    <div className="md:px-5 lg:px-7 pt-6 pb-11 md:py-14 flex flex-col brand-min-desktop:flex-row items-stretch brand-min-desktop:items-center gap-6 brand-min-desktop:gap-16 rounded-3xl md:rounded-brand-700 shadow-brand-800 bg-white ">
      <ForecastDayMetrics
        averageSurfDistance={dataSet.averageSurfDistance}
        averageSwell={dataSet.averageSwell}
        averageWind={dataSet.averageWind}
        windType={dataSet.windType}
        energy={dataSet.energy}
      />
      <div className="flex-1">
        <p className="uppercase text-lg font-medium mb-2.5 md:mb-3.5 px-4 md:px-9">
          {inBrazilRegion ? "MARÉS" : "Tides"}
        </p>
        <ForecastChart
          tides={dataSet.tides}
          sunrise={dataSet.sunrise}
          sunset={dataSet.sunset}
        />
      </div>
    </div>
  );
};
