/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
  DateTime: Date;
};

export type BuyMutation = { __typename?: 'MutationRoot', buy?: { __typename?: 'CheckoutBuy', checkout?: { __typename?: 'BuyResult', message: string, success: boolean } | null } | null };
export type BuyMutationVariables = Exact<{
  cart: Cart;
  payId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;

}>;

export type BuyResult = {
  __typename?: "BuyResult";
  message: Scalars["String"];
  success: Scalars["Boolean"];
};

export type Cart = {
  couponCode?: InputMaybe<Scalars["String"]>;
  extraItems: Array<CartItem>;
  selectedProduct?: InputMaybe<Scalars["String"]>;
  waves: Array<CartItem>;
};

export type CartItem = {
  id?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type CartResult = {
  __typename?: "CartResult";
  coupon?: Maybe<CouponValidationResponse>;
  productCurrency?: Maybe<Scalars["String"]>;
  products: Array<WaveProduct>;
  selectedProduct?: Maybe<Scalars["String"]>;
  totalPrice?: Maybe<Scalars["Float"]>;
};

export type Checkout = {
  __typename?: "Checkout";
  getPricesForCart?: Maybe<CartResult>;
};

export type CheckoutGetPricesForCartArgs = {
  cart: Cart;
  country?: InputMaybe<Scalars["String"]>;
};

export type CheckoutBuy = {
  __typename?: "CheckoutBuy";
  checkout?: Maybe<BuyResult>;
};

export type CheckoutBuyCheckoutArgs = {
  cart: Cart;
  payId?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
};

export type CouponValidationRequest = {
  amount: Scalars["Float"];
  couponName: Scalars["String"];
};

export type CouponValidationResponse = {
  __typename?: "CouponValidationResponse";
  message: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
  valid: Scalars["Boolean"];
};

export type Coupons = {
  __typename?: "Coupons";
  applyCoupon?: Maybe<CouponValidationResponse>;
};

export type CouponsApplyCouponArgs = {
  in: CouponValidationRequest;
};

export type MutationRoot = {
  __typename?: "MutationRoot";
  buy?: Maybe<CheckoutBuy>;
  track?: Maybe<Track>;
};

export type QueryRoot = {
  __typename?: "QueryRoot";
  checkout: Checkout;
  coupons: Coupons;
  waves: Waves;
};

export type SpanInfo = {
  __typename?: "SpanInfo";
  count: Scalars["Int"];
  endTime: Scalars["DateTime"];
  startTime: Scalars["DateTime"];
};

export enum SpanType {
  Day = "DAY",
  Hour = "HOUR",
}

export type Track = {
  __typename?: "Track";
  track?: Maybe<Scalars["Boolean"]>;
};

export type TrackTrackArgs = {
  info?: InputMaybe<Scalars["String"]>;
};

export type WaveItem = {
  __typename?: "WaveItem";
  createdAt?: Maybe<Scalars["DateTime"]>;
  gif?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  img?: Maybe<Scalars["String"]>;
  site?: Maybe<Scalars["String"]>;
  videoLength?: Maybe<Scalars["Int"]>;
};

export type WaveProduct = {
  __typename?: "WaveProduct";
  currency: Scalars["String"];
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  line3?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  pricePerItem: Scalars["Float"];
  promotionalPricePerItem: Scalars["Float"];
  totalPrice: Scalars["Float"];
  totalWavePrice: Scalars["Float"];
};

export type WaveSpansQuery = {
  endTime: Scalars["DateTime"];
  location: Scalars["String"];
  startTime: Scalars["DateTime"];
  type: SpanType;
};

export type Waves = {
  __typename?: "Waves";
  getWavePeriods?: Maybe<Array<Maybe<SpanInfo>>>;
  getWaves?: Maybe<Array<Maybe<WaveItem>>>;
};

export type WavesGetWavePeriodsArgs = {
  spanQuery: WaveSpansQuery;
};

export type WavesGetWavesArgs = {
  location: Scalars["String"];
  selectedDate: Scalars["DateTime"];
};

export type CountWavesQueryVariables = Exact<{
  spanQuery: WaveSpansQuery;
}>;

export type CountWavesQuery = {
  __typename?: "QueryRoot";
  waves: {
    __typename?: "Waves";
    getWavePeriods?: Array<{
      __typename?: "SpanInfo";
      startTime: Date;
      endTime: Date;
      count: number;
    } | null> | null;
  };
};

export type GetWavesQueryVariables = Exact<{
  dt: Scalars["DateTime"];
  location: Scalars["String"];
}>;

export type GetWavesQuery = {
  __typename?: "QueryRoot";
  waves: {
    __typename?: "Waves";
    getWaves?: Array<{
      __typename?: "WaveItem";
      id?: string | null;
      gif?: string | null;
      site?: string | null;
      createdAt?: Date | null;
      img?: string | null;
      videoLength?: number | null;
    } | null> | null;
  };
};

export type TrackMutationVariables = Exact<{
  info?: InputMaybe<Scalars["String"]>;
}>;

export type TrackMutation = {
  __typename?: "MutationRoot";
  track?: { __typename?: "Track"; track?: boolean | null } | null;
};

export type CartWavePricesQueryVariables = Exact<{
  cart: Cart;
  country: string;
}>;

export type CartWavePricesQuery = {
  __typename?: "QueryRoot";
  checkout: {
    __typename?: "Checkout";
    getPricesForCart?: {
      __typename?: "CartResult";
      selectedProduct?: string | null;
      totalPrice?: number | null;
      productCurrency: string | null;
      products: Array<{
        __typename?: "WaveProduct";
        pricePerItem: number;
        promotionalPricePerItem: number;
        totalWavePrice: number;
        line1?: string | null;
        line2?: string | null;
        line3?: string | null;
        totalPrice: number;
        name: string;
        currency: string;
      }>;
      coupon?: {
        __typename?: "CouponValidationResponse";
        message: string;
        price?: number | null;
        valid: boolean;
      } | null;
    } | null;
  };
};

export const CartWavePricesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "cartWavePrices" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "cart" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
          },
        },

        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "country" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkout" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "getPricesForCart" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "cart" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cart" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "country" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "country" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "products" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pricePerItem" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "promotionalPricePerItem",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalWavePrice" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "line1" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "line2" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "line3" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPrice" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "selectedProduct" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "productCurrency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPrice" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "coupon" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "price" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "valid" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartWavePricesQuery, CartWavePricesQueryVariables>;
export const CountWavesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "countWaves" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "spanQuery" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WaveSpansQuery" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "waves" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "getWavePeriods" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "spanQuery" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "spanQuery" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endTime" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountWavesQuery, CountWavesQueryVariables>;
export const GetWavesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWaves" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "dt" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DateTime" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "location" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "waves" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "getWaves" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "location" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "location" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "selectedDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dt" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "gif" } },
                      { kind: "Field", name: { kind: "Name", value: "site" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "img" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "videoLength" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWavesQuery, GetWavesQueryVariables>;
export const TrackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "track" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "info" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "track" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "track" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "info" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "info" },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TrackMutation, TrackMutationVariables>;
export const BuyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "buy" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "cart" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Cart" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "payId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "country" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "buy" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "cart" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cart" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "payId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "payId" },
                      },
                    },{
                      kind: "Argument",
                      name: { kind: "Name", value: "country" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "country" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuyMutation, BuyMutationVariables>;
