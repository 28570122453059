import { Button } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useRef,useState } from "react";
import axios from "axios";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { apolloClient } from "../../..";
import { graphql } from "../../../gql";
import styles from "./Watch.module.css";
import styles2 from "./TimeAndPlaceSelector.module.css";
import styles3 from "./SelectWaves.module.css";
import styles4 from "../Checkout/Checkout.module.css"
import watchStyles from "../../../pages/AppLayout.module.css";
import { WaveList } from "./WaveList";
import { useWaveContext } from "../../../context/CartContext";
import { useActor } from "@xstate/react";
import moment from "moment";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import { COUNTRY_CODE } from '../../../Api/Api';

import { endpoints } from "../../../Api/Api";
import whatsappImg from "../../../assets/videography/whatsapp.svg";
import cardMart from "../../../assets/cardMart.svg";
import PlayIcon from "../../../assets/playIcon.svg";
import faqIcon from "../../../assets/faqIcon.svg";

import { FAQ } from "../../Modal/faq"
import { inBrazilRegion } from '../../../utils/region';

export type WaveInfo = { id?: string | null, gif?: string | null, site?: string | null, createdAt?: Date | null, img?: string | null, videoLength?: Number | null };

const GET_WAVES = graphql(/* GraphQL */ `
query getWaves($dt: DateTime!, $location: String!){
  waves {
    getWaves(location: $location, selectedDate: $dt) {
      id,gif, site,createdAt,img,videoLength
    }
  }
}`);

type WaveParams = {
  location: String;
  day: String;
  time: String;
}

export const loadWaves = async ({ params }) => {

  const dateStr = `${params.day}:${params.time}`;
  const dt = dayjs(dateStr, 'YYYYMMDD:HH').toDate();

  const ret = await apolloClient.query({
    query: GET_WAVES, variables: {
      location: params.location,
      dt: dt
    }
  });

  return ret.data.waves.getWaves;
}

let locationTitles = {}; // Define an empty object before the conditions

if (COUNTRY_CODE === "PT") {
  locationTitles = {
    'nova-praia': {
      title1: 'Nova Praia',
      title2: 'Costa da Caparica'
    },
    'carcavelos-tunnel': {
      title1: 'Tunnel',
      title2: 'Carcavelos'
    },
    'peniche-bar-do-bruno': {
      title1: 'Bar do Bruno',
      title2: 'Baleal Peniche'
    },
    'caparica-tarquinio': {
      title1: 'Praia do Tarquínio',
      title2: 'Costa da Caparica'
    },
    'caparica-fonte-da-telha': {
      title1: 'Swara',
      title2: 'Fonte Da Telha'
    },
    'pt-caparica-santo-antonio': {
      title1: 'Praia de Santo António',
      title2: 'Costa da Caparica'
    },
    'pt-ericeira-ribeira-dilhas': {
      title1: 'Praia de Ribeira Dilhas',
      title2: 'Ericeira'
    },
    'aljezur-arrifana': {
      title1: 'Swara',
      title2: 'Fonte Da Telha'
    }
  };
} else if (COUNTRY_CODE === "BR") {
  locationTitles = {
    'br-sp-guaruja-praia-do-tombo': {
      title1: 'Praia do Tombo',
      title2: 'Guarujá'
    },
    'br-sp-maresias-sao-sebastiao': {
      title1: 'Maresias',
      title2: 'São Sebastião'
    },
    'br-sp-ubatuba-itamambuca': {
      title1: 'Itamambuca',
      title2: 'Ubatuba'
    },
    'br-sp-ubatuba-vermelha-do-norte': {
      title1: 'Vermelha do Norte',
      title2: 'Ubatuba'
    }
  };
}


export const SelectWaves = () => {
  const waves = useLoaderData() as Array<Partial<WaveInfo>>;
  const params = useParams();
  const location = params.location
  const dateStr = `${params.day}:${params.time}`;
  const dt = dayjs(dateStr, 'YYYYMMDD:HH').toDate();
  const navigate = useNavigate();
  const { waveService } = useWaveContext();
  const [current] = useActor(waveService);
  const scrollRef = useRef(null);

  const [backButtonPopup, setBackButtonPopup] = useState(false)

  const [faqHandler, setFaqHandler] = useState(false)
  const [allDates, setAllDates] = useState({})
  const [passType, setPassType] = useState()
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);


  useEffect(() => {
    const locationFromLocal = JSON.parse(localStorage.getItem("location"))
    const dateFromLocal = JSON.parse(localStorage.getItem("date"))
    const allWaves = JSON.parse(localStorage.getItem("waves"))
    waveService.send({
      type: 'reset',
    })
    if(locationFromLocal === params.location && dateFromLocal === params.day && allWaves){
        const wavesFromLocal = JSON.parse(localStorage.getItem("waves"))
        for (let key in wavesFromLocal) {
            const wave = wavesFromLocal[key];
            waveService.send({
              type: 'updateWaves',
              data: wave
            })

          }
    }
    else{
        localStorage.setItem("location", JSON.stringify(params.location))
        localStorage.setItem("date", JSON.stringify(params.day))
        localStorage.setItem("waves", JSON.stringify({}))
    }
     
  }, [])  

  const handleCreateSesssion = () => {
    const data = current.context.cart.waves
    setIsLoadingCheckout(true);
    if(data.length <= 0){
      setIsLoadingCheckout(false);
      return
    }
    const customertoken = localStorage.getItem("token");
      axios
        .post(
          endpoints.CREATE_SESION_FOR_ACTIVE_SUBSCRIPTION,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${customertoken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "True") {
            setIsLoadingCheckout(false);
            // Swal.fire(response.data.message, "", "success");
            waveService.send({
              type: 'reset'
            });
            localStorage.removeItem("date")
            localStorage.removeItem("waves")
            localStorage.removeItem("location")

            Swal.fire(response.data.message, "", "success");
            navigate("/mysession")
          } else {
            setIsLoadingCheckout(false);
            // Swal.fire(response.data.message, "", "error");
            navigate('/session/checkout')
          }
        })
        .catch((error) => {
          setIsLoadingCheckout(false);
          console.log(error)
        });
  }

  const handleClick = (bool:boolean) => {
    setFaqHandler(bool)
  }


  return (
    <>
      <div className={watchStyles.watch}>
        <div className={watchStyles.watchContainer}>
          <div className={styles.HeadingContainer} ref={scrollRef}>
            <div>
              <Button
                className={`${styles2["button-title-btn"]} ${styles.HeaderFirst}`}
                onClick={() => navigate(-1)}
                // onClick={() => setBackButtonPopup(true)}
              >
                <MdOutlineArrowBackIosNew />
                {inBrazilRegion ? "Horário" : "Time"}
              </Button>
            </div>
            <div>
              <div className={styles.faqsButton}>
                <button onClick={() => setFaqHandler(true)}>
                  FAQ
                  <img src={faqIcon} alt="" />
                </button>
              </div>
            </div>
            <div className={styles.HeaderLast}>
              <h2>{locationTitles[params.location].title1}</h2>
              <h3>{locationTitles[params.location].title2}</h3>
            </div>
          </div>

          <h3 className={styles.header}>
            {inBrazilRegion ? (
              <span>
                PRÉ-VISUALIZAÇÕES SÃO DO INICIO E FINAL DA ONDA
                <br></br>
                <span className={styles.spanFontSize}>
                  SE UMA <span style={{ color: "red" }}>LINHA VERMELHA</span>{" "}
                  ApARECER, o surfista está cruzando a área de gravação
                </span>
              </span>
            ) : (
              <span>
                Previews of start and end of wave | 20% OFF First Purchase:{" "}
                <b>WAVE20</b>
                <br></br>
                <span className={styles.spanFontSize}>
                  If a <span style={{ color: "red" }}>Red Line</span> Appears,
                  Surfer is crossing Recording Area
                </span>
              </span>
            )}{" "}
          </h3>

          <WaveList
            waves={waves}
            sccrollTime={dt}
            selectedWaves={current.context.cart.waves}
            onInitialScroll={() => scrollRef.current.scrollIntoView()}
            onChange={(wave) => {
              waveService.send({
                type: "updateWaves",
                data: wave,
              });
              // update waves localStorage too
              const wavesFromLocal = JSON.parse(localStorage.getItem("waves"));
              if (wavesFromLocal) {
                if (!wavesFromLocal[wave?.id]) {
                  wavesFromLocal[wave.id] = wave;
                  localStorage.setItem("waves", JSON.stringify(wavesFromLocal));
                } else {
                  delete wavesFromLocal[wave?.id];
                  localStorage.setItem("waves", JSON.stringify(wavesFromLocal));
                }
              }
            }}
          />
          <div className={styles.wavesSeclect}>
            <button className={styles.btn} onClick={() => navigate("/cart")}>
              <img src={cardMart} alt="" />
              <span>{current.context.cart.waves.length}</span>
              {inBrazilRegion ? "ONDAS" : "WAVES"} <b> {!inBrazilRegion && "SELECTED"}</b>
            </button>
            <div className={styles.nextButton}>
              <button
                className={styles.btnNext}
                onClick={() => {
                  handleCreateSesssion();
                }}
              >
                {isLoadingCheckout ? (
                  <RotatingLines
                    strokeColor="black"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="60"
                    visible={true}
                  />
                ) : (
                  <>
                    <>{inBrazilRegion ? "PRÓXIMO" : "NEXT"}</>
                    <img src={PlayIcon} alt="" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {backButtonPopup == true ? (
          <div className={`${styles4.modal}`}>
            <div className={`${styles4.blur}`}></div>
            <div className={`${styles4.boxWhite}`}>
              <h2>ARE YOU SURE?</h2>
              <div className={`${styles4.bgColor}`}>
                <h3>YOU WILL LOOSE THE WAVES YOU'VE SELECTED, IF ANY</h3>
                <div className={`${styles4.btnAll}`}>
                  <a className={`${styles4.btn}`} onClick={() => navigate(-1)}>
                    YES
                  </a>
                  <a
                    className={`${styles4.btn} ${styles4.active}`}
                    onClick={() => setBackButtonPopup(false)}
                  >
                    NO
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {faqHandler ? <FAQ handleClick={handleClick} /> : <></>}
    </>
  );
}