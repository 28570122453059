import styles from "../Session/Checkout/Checkout.module.css";
import clockIcon from "../../../src/assets/LandingNew/clockIcon.svg";
import checkBoxIcon from "../../../src/assets/LandingNew/checkBoxIcon.svg";
import { useEffect, useRef, useState } from "react";
import { endpoints } from "../../Api/Api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { inBrazilRegion } from "../../utils/region";

export const FullPreview = ({ handleClick, vidSrc, onChange, selected }) => {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [stVideo, setStvideo] = useState(vidSrc.video);
  const siteId = vidSrc.id;
  const [isLoading, setIsLoading] = useState(true);
  const [Permit, setPermit] = useState(false);

  useEffect(() => {
    const customertoken = localStorage.getItem("token");
    axios
      .get(`${endpoints.GETWAVE}/?id=${siteId}`, {
        headers: {
          Authorization: `Bearer ${customertoken}`,
        },
      })
      .then((response) => {
        if (response.data.status === "True") {
          const videoLink = response.data.premiumlink;
          setStvideo(videoLink);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        } else {
          setIsLoading(false);
          // const videoLink = response.data.premiumlink
          // setStvideo(videoLink)
          // setPermit(true)
          // setTimeout(() => {
          //   setIsLoading(false)
          // }, 1000)
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   const videoElement = videoRef.current;
  //   if (videoElement && stVideo && !isLoading) {
  //     // Check if autoplay is supported
  //     const playPromise = videoElement?.play();
  //     const checkTime = setInterval(() => {
  //       if (videoElement.currentTime > 5) {
  //         videoElement.pause();
  //         clearInterval(checkTime);
  //       }
  //     }, 1000);
  //   }
  // }, [stVideo, isLoading]);

  if (isLoading) {
    return (
      <>
        <div className={styles.fullPreview}>
          <div className={styles.background}></div>
          <div className={styles.fullModal}>
            <div className={styles.fullHeading}>
              {inBrazilRegion ? (
                <h2>Prévia completa</h2>
              ) : (
                <h2>Full preview</h2>
              )}
              <button
                className={styles.closeFaq}
                onClick={() => handleClick(false)}
              >
                X
              </button>
            </div>

            <div className={styles.fullVideo}>
              <img src={"/videoLoading.png"} alt="" />
              {/* <video autoPlay loop playsInline>
              <source src={`${stVideo}`} poster={fullPosterImg}/>
            </video> */}

              {/* <div style={{position: 'absolute', top: '20px', left: '20px',}}>
              <button style={{backgroundColor: '#FABA06', color: '#01243C', fontWeight: '600', fontStyle: 'italic', fontFamily: 'Exo 2', padding: '10px 30px', borderRadius: '50px', border: 'none', fontSize: '16px',}}>PREMIUM</button>
            </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className={styles.fullPreview}>
        <div className={styles.background}></div>
        <div className={styles.fullModal}>
          <div className={styles.fullHeading}>
            {inBrazilRegion ? <h2>Prévia completa</h2> : <h2>Full preview</h2>}
            <button
              className={styles.closeFaq}
              onClick={() => handleClick(false)}
            >
              X
            </button>
          </div>

          <div className={styles.fullVideo}>
            {stVideo.includes("s3") && (
              <video autoPlay loop playsInline poster={"/videoLoading.png"}>
                <source src={`${stVideo}`} />
              </video>
            )}
            {stVideo.includes("s3") == false && (
              <video autoPlay loop playsInline poster={"/videoLoading.png"}>
                <source src={`${stVideo}`} />
              </video>
            )}
            {/* //if user have acces to full preview video */}
            {/* {Permit === false && <video autoPlay loop playsInline poster={'/videoLoading.png'}>
            <source src={`${stVideo}`} />
          </video>} */}
            {/* permit true it means user does not have access of full preview video */}
            {/* {Permit && <video autoPlay loop playsInline poster={'/videoLoading.png'} ref={videoRef}>
            <source src={`${stVideo}`} />
          </video>} */}
            {/* default if high resolution videos does not avail */}
            {/* {stVideo.includes("s3") == false && <video autoPlay loop playsInline poster={'/videoLoading.png'}>
            <source src={`${stVideo}`}/>
          </video>}
           */}
            {/* <img src={fullPosterImg} alt="" /> */}

            {stVideo.includes("s3") === false && (
              <div style={{ position: "absolute", top: "20px", left: "20px" }}>
                <button
                  className={styles.PremiumBtn}
                  style={{
                    backgroundColor: "#FABA06",
                    color: "#01243C",
                    fontWeight: "600",
                    fontStyle: "italic",
                    fontFamily: "Exo 2",
                    padding: "10px 30px",
                    borderRadius: "50px",
                    border: "none",
                    fontSize: "16px",
                  }}
                >
                  PREMIUM
                </button>
              </div>
            )}
          </div>

          {stVideo.includes("s3") && (
            <div className={styles.fullcheckBox}>
              <h3> Select wave</h3>
              <input
                type="checkbox"
                onChange={onChange}
                defaultChecked={selected}
              />
            </div>
          )}

          {stVideo.includes("s3") === false && (
            <div className={styles.fullsurfeyeBox}>
              <h3>
                Surf Eye <span>Unlimited</span>
              </h3>
              <ul>
                <li>
                  <span>
                    <img src={checkBoxIcon} alt="check" />
                  </span>
                  <span>
                    {inBrazilRegion
                      ? "Vídeo Prévias Completas"
                      : "full length previews"}
                  </span>
                </li>
                <li>
                  <span>
                    <img src={checkBoxIcon} alt="check" />
                  </span>
                  <span>
                    {inBrazilRegion ? "Todas as LOCALIZAÇÕES" : "All locations"}
                  </span>
                </li>
                <li>
                  <span>
                    <img src={checkBoxIcon} alt="check" />
                  </span>
                  <span>
                    {inBrazilRegion
                      ? "Downloads ilimitados"
                      : "Unlimited videos"}
                  </span>
                </li>
                <li>
                  <span>
                    <img src={clockIcon} alt="clock" />
                  </span>
                  <span>
                    {inBrazilRegion
                      ? "Mais recursos em breve "
                      : "More features soon"}
                  </span>
                </li>
              </ul>

              <div className={styles.subscriptBox}>
                {inBrazilRegion ? (
                  <h4>
                    R$49 <span>/ mês</span>
                  </h4>
                ) : (
                  <h4>
                    €9.99 <span>/ month</span>
                  </h4>
                )}

                <button
                  onClick={() => {
                    // if video not selected then select first then proceed to checkout page
                    if (!selected) {
                      onChange();
                    }
                    // to checkout
                    localStorage.setItem("packageSelected", "month");
                    navigate("/session/checkout");
                  }}
                >
                  {inBrazilRegion ? "comece agora" : "subscribe"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
