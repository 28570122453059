import { DownloadIcon, LocationsIcon, PreviewIcon } from '../../../assets/SitePage/icons';
import { inBrazilRegion } from '../../../utils/region';

export const features = [
  {
    title: inBrazilRegion ? "Downloads ilimitados" : 'Unlimited downloads',
    description: inBrazilRegion ? "Para reviver, compartilhar e melhorar seu surfe a cada onda" : 'To relive, share and improve your surfing with every wave',
    icon: DownloadIcon,
  },
  {
    title: inBrazilRegion ? "Todas as LOCALIZAÇÕES" : 'All locations',
    description: inBrazilRegion ? "Surfe em qualquer lugar e obtenha vídeos de todas as nossas câmeras" : 'Surf anywhere and get videos from all our cameras',
    icon: LocationsIcon,
  },
  {
    title: inBrazilRegion ? "Vídeo Prévias Completas" : 'Full length previews',
    description: inBrazilRegion ? "Assista à vídeo prévia completa de cada onda" : 'Watch the full length preview of each wave',
    icon: PreviewIcon,
  },
] as const;
