import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DateTimePicker from "react-datetime-picker";
// import {Link} from 'react-router-dom';

import { AiOutlineMenu } from "react-icons/ai";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import { regions } from "../../pages/LandingPage";
import { navbarLocations, navbarPools } from "../SitePage/navbar/routes";
import { Link } from "react-router-dom";
import { inBrazilRegion } from "../../utils/region";

const IconHam = () => {
  return (
    <div className="hamburger-menu-icon">
      <AiOutlineMenu size={27} />
    </div>
  );
};

export default function Hamburger({
  target,
  children,
  links,
  type,
  authHandler,
  handleSetLocationReference,
  handleRegionItemClick,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div onClick={handleClick}>{target || <IconHam />}</div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {type === "dateIp" ? (
          <div className="videoviewinput">
            <DateTimePicker
              className="videoviewinput-input"
              onChange={handleChange}
              value={value}
            />
          </div>
        ) : (
          // links.map((l,id)=><MenuItem sx={{color : '#01243C', paddingX : '40px', borderBottom : '1px solid lightgray' , fontFamily : ['Exo 2','sans-serif'], fontWeight :'bold', textTransform : 'uppercase', fontStyle : 'italic'}} key={id} onClick={()=> {
          //   handleClose()
          //   switch(l.title){
          //     case "Location":
          //       handleSetLocationReference('location')
          //       break;
          //     case "highlights":
          //       handleSetLocationReference('highlight')
          //       break;
          //     case "pricing":
          //       handleSetLocationReference('price')
          //       break;
          //     default:
          //       break
          //   }
          // }}><span>{l.title}</span></MenuItem>)
          <>
            {/* highlight */}
            <MenuItem
              sx={{
                color: "#01243C",
                paddingX: "40px",
                borderBottom: "1px solid lightgray",
                fontFamily: ["Exo 2", "sans-serif"],
                fontWeight: "bold",
                textTransform: "uppercase",
                fontStyle: "italic",
              }}
              onClick={() => {
                handleClose();
                handleSetLocationReference("howitworksmobile");
              }}
            >
              <span>{links[0].title}</span>
            </MenuItem>
            {/* location */}
            {/* <MenuItem sx={{color : '#01243C', paddingX : '40px', borderBottom : '1px solid lightgray' , fontFamily : ['Exo 2','sans-serif'], fontWeight :'bold', textTransform : 'uppercase', fontStyle : 'italic'}}  onClick={()=> {
            handleClose()
            handleSetLocationReference('highlightmobile')
          }}><span>{links[1].title}</span></MenuItem> */}
            {/* another location dropdown */}
            <NavbarMenuPools
              authHandler={authHandler}
              handleSetLocationReference={handleSetLocationReference}
              handleRegionItemClick={handleRegionItemClick}
              handleClose={handleClose}
            />

            <NavbarMenuLocation
              authHandler={authHandler}
              handleSetLocationReference={handleSetLocationReference}
              handleRegionItemClick={handleRegionItemClick}
              handleClose={handleClose}
            />
            {/* price */}
            <MenuItem
              sx={{
                color: "#01243C",
                paddingX: "40px",
                borderBottom: "1px solid lightgray",
                fontFamily: ["Exo 2", "sans-serif"],
                fontWeight: "bold",
                textTransform: "uppercase",
                fontStyle: "italic",
              }}
              onClick={() => {
                handleClose();
                handleSetLocationReference("price");
              }}
            >
              <span>{links[3].title}</span>
            </MenuItem>
          </>
        )}

        <NavbarMenu authHandler={authHandler} handleClose={handleClose} />
      </Menu>
    </div>
  );
}

const Dropdown = ({
  links,
  targetHandler,
  dropdown_title,
  icon,
  handleRegionItemClick,
  handleClose,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    // You can perform additional actions based on the selected option, such as navigation
    console.log(`Selected option: ${option.title}`);
  };

  return (
    <li className="locationDropdown">
      <div className="dropdown">
        <div className="dropdown-header" onClick={handleDropdownToggle}>
          <span>
            {/* {selectedOption ? selectedOption.title : <FaRegUser />} */}
            {icon === true ? <FaRegUser /> : dropdown_title}
          </span>
          <span>{isDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
        </div>
        {isDropdownOpen && (
          <div className="dropdown-options">
            {links.map((link, index) => (
              <div className="dropdownmenu">
                <Link to={link.url} key={link.id}>
                  {link.title}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </li>
  );
};

// Example usage
const NavbarMenu = ({ authHandler, handleClose }) => {
  const dropdownLinks = inBrazilRegion
    ? [
        { title: "Conecte-se", href: "#VideosComponent" },
        { title: "Registrar", href: "#LocationsComponent" },
      ]
    : [
        { title: "Login", href: "#VideosComponent" },
        { title: "Register", href: "#LocationsComponent" },
      ];

  return (
    <Dropdown
      targetHandler={authHandler}
      dropdown_title="menu"
      links={dropdownLinks}
      icon={true}
      handleClose={handleClose}
    />
  );
};

const NavbarMenuLocation = ({
  authHandler,
  handleSetLocationReference,
  handleRegionItemClick,
  handleClose,
}) => {
  const dropdownLinks = navbarLocations.map((x) => {
    return { id: x.id, title: x.label, url: x.href };
  });

  return (
    <>
      {inBrazilRegion ? (
        <Dropdown
          targetHandler={handleSetLocationReference}
          dropdown_title="Localizações"
          links={dropdownLinks}
          icon={false}
          handleRegionItemClick={handleRegionItemClick}
          handleClose={handleClose}
        />
      ) : (
        <Dropdown
          targetHandler={handleSetLocationReference}
          dropdown_title="Beachcams"
          links={dropdownLinks}
          icon={false}
          handleRegionItemClick={handleRegionItemClick}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

const NavbarMenuPools = ({
  authHandler,
  handleSetPoolsReference,
  handleRegionItemClick,
  handleClose,
}) => {
  const dropdownLinks = navbarPools.map((x) => {
    return { id: x.id, title: x.label, url: x.href };
  });

  return (
    <>
      {inBrazilRegion ? (
        <Dropdown
          targetHandler={handleSetPoolsReference}
          dropdown_title="Nossas Piscinas"
          links={dropdownLinks}
          icon={false}
          handleRegionItemClick={handleRegionItemClick}
          handleClose={handleClose}
        />
      ) : (
        <Dropdown
          targetHandler={handleSetPoolsReference}
          dropdown_title="Our Pools"
          links={dropdownLinks}
          icon={false}
          handleRegionItemClick={handleRegionItemClick}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
