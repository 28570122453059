import { useInterpret } from "@xstate/react";
import React, { useContext, useReducer } from "react";
import { Provider, useState, createContext } from "react";
import {
  assign,
  createMachine,
  DoneInvokeEvent,
  EventObject,
  InterpreterFrom,
} from "xstate";
import { apolloClient } from "..";
import { TimeAndPlace } from "../components/Session/Watch/TimeAndPlaceSelector";
import { WaveInfo } from "../components/Session/Watch/Watch2";
import { graphql } from "../gql";
import {
  CartWavePricesQuery,
  CouponValidationResponse,
  WaveProduct,
} from "../gql/graphql";
import { ApolloQueryResult } from "@apollo/client";
import { TRACK } from "../services/track";
import { useNavigate } from "react-router-dom";
import { inBrazilRegion } from "../utils/region";

type CartContent = {
  waves: Array<WaveInfo>;
  coaching: boolean;
};

interface Context {
  timeAndPlace?: TimeAndPlace;
  waves?: Array<Partial<WaveInfo>>;
  cart: CartContent;
  products: Array<WaveProduct>;
  selectedProduct?: WaveProduct;
  couponCode?: string;
  coupon?: CouponValidationResponse;
  totalPrice: number;
  stripeBuyId?: string;
  message?: string;
}

const CART_PRICES = graphql(/* GraphQL */ `
query cartWavePrices($cart: Cart!, $country: String) {
  checkout {
    getPricesForCart(cart: $cart, country: $country) {
      products {
        pricePerItem
        promotionalPricePerItem
        totalWavePrice
        line1
        line2
        line3
        totalPrice
        name
       currency
      }
      selectedProduct
      productCurrency
      totalPrice
      coupon {
        message
        price
        valid
      }
    } 
  }
}
`);

const CART_BUY = graphql(/* GraphQL */ `
mutation buy($cart: Cart!, $payId: String, country: String) {
  buy {
    checkout(cart: $cart, payId: $payId, country: $country) {
      message,
      success
    }
  }
}
`);

export const errorAction = assign({
  error: (_, event: DoneInvokeEvent<any>) => {
    if (
      event.data?.networkError?.statusCode == 500 ||
      event.data?.networkError?.statusCode == 401
    ) {
      return "auth";
    }
    return "unknown";
  },
});

const determineSelectedProduct = (
  ctx: Context,
  event: DoneInvokeEvent<any>,
) => {
  const evt = event.data.data as CartWavePricesQuery;
  const ret = evt.checkout.getPricesForCart.products.find(
    (p) => p.name == evt.checkout.getPricesForCart.selectedProduct,
  );
  return ret;
};

let allWavesData = JSON.parse(localStorage.getItem("waves")) || {};
let res = [];
for (let prop in allWavesData) {
  res.push(allWavesData[prop]);
}
// console.log(res, "allWavesData from local storage------------")
// const lengthOfllWavesData = Object.keys(allWavesData).length;
// if(res.length <= 0) {
//   allWavesData = [allWavesData]
// }else{
//   allWavesData = []
// }
export const watchAndBuySM = createMachine<Context, any>({
  id: "watch",
  initial: "checkout",
  context: {
    cart: {
      waves: res, // []
      coaching: false,
    },
    products: [],
    totalPrice: 0,
    message: undefined,
  },
  on: {
    reset: {
      target: "checkout",
      actions: assign({
        cart: () => ({
          waves: [],
          coaching: false,
        }),
      }),
    },
  },

  states: {
    checkout: {
      invoke: [
        {
          id: "invokeGetProducts",
          src: async (context, _) => {
            const ret = apolloClient.query({
              query: CART_PRICES,
              variables: {
                cart: {
                  waves: context.cart.waves.map((w) => ({
                    id: w.id,
                    type: "wave",
                  })),
                  extraItems: context.cart.coaching ? [{}] : [],
                  couponCode: context.couponCode,
                  selectedProduct: context.selectedProduct?.name,
                },
                country: inBrazilRegion ? "BR" : "PT",
              },
            });
            return ret;
          },
          onDone: {
            actions: assign({
              products: (_ctx, event) => {
                const evt = event.data.data as CartWavePricesQuery;
                return evt.checkout.getPricesForCart.products;
              },
              selectedProduct: determineSelectedProduct,
              totalPrice: (_ctx, event) => {
                const evt = event.data.data as CartWavePricesQuery;
                return evt.checkout.getPricesForCart.totalPrice;
              },
              coupon: (_ctx, event) => {
                const evt = event.data.data as CartWavePricesQuery;
                return evt.checkout.getPricesForCart.coupon;
              },
            }),
          },
          onError: {
            actions: errorAction,
          },
        },
      ],
      on: {
        updateWaves: {
          target: "checkout",
          actions: [
            assign({
              cart: (ctx, event) => {
                const cart: CartContent = ctx.cart;
                const newState = [...cart.waves];
                const idx = newState.findIndex((v) => v.id == event.data.id);
                if (idx >= 0) {
                  newState.splice(idx, 1);
                } else {
                  newState.push(event.data);
                }
                cart.waves = newState;
                return cart;
              },
              selectedProduct: undefined,
            }),
            (context, event) => {
              const eventType: string = event.type as string;
              if (eventType.indexOf("updateWaves") >= 0) {
                return apolloClient.mutate({
                  mutation: TRACK,
                  variables: {
                    info: `[${eventType}]CartCount: ${context.cart.waves.length}`,
                  },
                });
              }
            },
          ],
        },
        buy: [
          {
            target: "buy",
            actions: assign({
              stripeBuyId: (_ctx, event) => event.payId,
            }),
          },
        ],
        coaching: {
          target: "checkout",
          actions: [
            assign({
              cart: (ctx, event) => {
                const cart: CartContent = ctx.cart;
                cart.coaching = event.data;
                return cart;
              },
            }),
            "trackCheckout",
          ],
        },
        selectProduct: {
          target: "checkout",
          actions: [
            assign({
              selectedProduct: (
                ctx,
                event: EventObject & { product: WaveProduct },
              ) => {
                return event.product;
              },
            }),
            "trackCheckout",
          ],
        },
        coupon: {
          target: "checkout",
          actions: [
            assign({
              couponCode: (ctx, event) => {
                return event.couponCode;
              },
            }),
            "trackCheckout",
          ],
        },
      },
    },
    buy: {
      invoke: [
        {
          id: "invokeBuy",
          src: async (context, _) => {
            const ret = apolloClient.mutate({
              mutation: CART_BUY,
              variables: {
                cart: {
                  waves: context.cart.waves.map((w) => ({
                    id: w.id,
                    type: "wave",
                  })),
                  extraItems: context.cart.coaching ? [{}] : [],
                  couponCode: context.couponCode,
                  selectedProduct: context.selectedProduct?.name,
                },
                payId: context.stripeBuyId,
                country: inBrazilRegion ? "BR" : "PT",
              },
            });
            return ret;
          },
          onDone: [
            {
              target: "end",
              actions: assign({
                message: (_ctx, event) => event.data.data.buy.checkout.message,
              }),
              cond: (ctx, event) => event.data.data.buy.checkout.success,
            },
            {
              target: "checkout",
              actions: assign({
                message: (_ctx, event) => event.data.data.buy.checkout.message,
              }),
            },
          ],
          onError: {
            actions: (ctx, event) => {
              console.log(event.data);
            },
          },
        },
      ],
    },
    end: {},
  },
});

const WaveContext = createContext({
  waveService: {} as InterpreterFrom<typeof watchAndBuySM>,
});

export const CartContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const waveService = useInterpret(watchAndBuySM, {
    actions: {
      navigateCheckout: (context, event) => {
        if (event.type == "prev") {
          navigate(-1);
        }
        navigate("/session/checkout");
      },
      trackCheckout: (context, event) => {
        const eventType: string = event.type as string;
        console.log(
          `[Checkout ${eventType}]CartCount: ${context.cart.waves.length}`,
        );
        return apolloClient.mutate({
          mutation: TRACK,
          variables: {
            info: `[Checkout ${eventType}]CartCount: ${context.cart.waves.length}`,
          },
        });
      },
    },
  });

  return (
    <WaveContext.Provider value={{ waveService: waveService }}>
      {children}
    </WaveContext.Provider>
  );
};

export function useWaveContext() {
  const ret = useContext(WaveContext);
  return ret;
}
