import React, { useState, useEffect, useRef } from "react";
import "./Navbar.styles.scss";
import Logo from "../../assets/logo2.svg";
import Hamburger from "../Hamburger/Hamburger";
import { Link } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { regions } from "../../pages/LandingPage";
import { useSearchParams } from "react-router-dom";
import { inBrazilRegion } from "../../utils/region";
const NavbarItem = ({ title, onClick, link, handleClick }) => {
  const [isActive, setIsActive] = useState(false);

  const onClickHandler = (event) => {
    // Pehle se active link ka active class hata do
    const activeLink = document.querySelector(".navbar__link.active");
    if (activeLink) {
      activeLink.classList.remove("active");
    }

    // Naye link ko active kar do
    event.target.closest(".navbar__link").classList.add("active");
    handleClick();
  };
  return (
    <div className={`navbar__link ${isActive ? "active" : ""}`}>
      <a className="" onClick={onClickHandler}>
        {title}
      </a>
    </div>
  );
};

const Navbar = ({
  isHome,
  isAuth,
  authHandler,
  handleClick,
  handleRegionItemClick,
}) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const section = searchParams.get("section");
      if (!section) return;

      handleClick(section);

      if (section === "register") {
        authHandler(true);
        setIsOpenOnbording(false);
      } else if (section === "login") {
        authHandler();
        setIsOpenOnbording(false);
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [searchParams]);

  console.log("[LandingPage:searchParams]:", searchParams.get("section"));

  const [age, setAge] = React.useState("");
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRefPools = useRef(null);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [isOpenBeachcams, setIsOpenBeachcams] = useState(false);
  const [isOpenPools, setIsOpenPools] = useState(false);
  const [isOpenOnbording, setIsOpenOnbording] = useState(false);

  const toggleDropdownPools = () => {
    setIsOpenPools(!isOpenPools);
    setIsOpenBeachcams(false);
    setIsOpenOnbording(false);
  };

  const toggleDropdown = () => {
    setIsOpenBeachcams(!isOpenBeachcams);
    setIsOpenOnbording(false);
    setIsOpenPools(false);
  };

  const toggleDropdownOnbording = () => {
    setIsOpenOnbording(!isOpenOnbording);
    setIsOpenBeachcams(false);
    setIsOpenPools(false);
  };

  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefPools.current &&
        !dropdownRefPools.current.contains(event.target)
      ) {
        setIsOpenPools(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenBeachcams(false);
      }
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setIsOpenOnbording(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [setIsOpenPools, setIsOpenBeachcams, setIsOpenOnbording]);

  return (
    <div className={isSticky ? "navbar sticky" : "navbar"}>
      <div className="navbar-container">
        <Link to="/">
          <div className="navbar__logo">
            <img src={Logo} alt="" />
          </div>
        </Link>
        <div className="navbar__links">
          {inBrazilRegion ? (
            <NavbarItem
              title="Como funciona"
              handleClick={() => handleClick("howitworks")}
            />
          ) : (
            <NavbarItem
              title="How it works"
              handleClick={() => handleClick("howitworks")}
            />
          )}
          {/* <NavbarItem
            title="highlights"
            handleClick={() => handleClick("highlight")}
          /> */}
          <div className="profileDropdown" ref={dropdownRefPools}>
            <div className="HeaderDropdown">
              <span onClick={toggleDropdownPools}>
                {" "}
                {inBrazilRegion ? "Nossas Piscinas" : "Our Pools"}{" "}
                <IoIosArrowDown />
              </span>
              <div className="dropdownMenu">
                {isOpenPools ? (
                  <div className="dropdownBox">
                    {inBrazilRegion ? (
                      <Link
                        to="https://wavepools.surfeye.video/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Piscinas de ondas
                      </Link>
                    ) : (
                      <Link
                        to="https://wavepools.surfeye.video/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        All Our Wavepools
                      </Link>
                    )}
                    <Link
                      to="https://alaiabay.surfeye.video/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Alaïa Bay
                    </Link>
                    <Link
                      to="https://citywavevilnius.surfeye.video/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Citywave Vilnius
                    </Link>
                    <Link
                      to="https://jsa.surfeye.video/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Jochen Schweizer Arena
                    </Link>
                    <Link
                      to="https://www.instagram.com/fazendadagrama/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Praia da Grama
                    </Link>
                    <Link
                      to="https://rif010.surfeye.video/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RiF010
                    </Link>
                    <Link
                      to="https://skudinsurfad.surfeye.video/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skudin Surf AD
                    </Link>
                    <Link
                      to="https://surfcenter.net/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Surf Center
                    </Link>
                    <Link
                      to="https://surf-langenfeld.surfeye.video/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Surf Langefeld
                    </Link>
                    <Link
                      to="https://urbansurf.ch/de/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Urbansurf Zürich
                    </Link>
                    <Link
                      to="https://wellenwerk.surfeye.video/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wellenwerk
                    </Link>
                    <Link
                      to="https://wakeparadise.surfeye.video/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wakeparadise
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="profileDropdown" ref={dropdownRef}>
            <div className="HeaderDropdown">
              <span onClick={toggleDropdown}>
                {" "}
                {inBrazilRegion ? "Localizações" : "BeachCams"}{" "}
                <IoIosArrowDown />
              </span>
              <div className="dropdownMenu">
                {isOpenBeachcams ? (
                  <div className="dropdownBox">
                    {inBrazilRegion ? <>

                      <Link
                        to="/beachcam/br-praia-do-tombo"
                      >
                        Praia do Tombo - Guarujá

                      </Link>
                      <Link
                        to="/beachcam/br-maresias"
                      >
                        Maresias - São Sebastiao
                      </Link>
                      <Link
                        to="/beachcam/br-itamambuca"
                      >
                        Itamambuca  - Ubatuba
                      </Link>
                      <Link
                        to="/beachcam/br-vermelha-do-Norte"
                      >
                        {" "}
                        Vermelha do Norte - Ubatuba

                      </Link>
                    </> : <>

                      <Link
                        to="/beachcam/bar-do-bruno"
                      >
                        Bar do Bruno - Baleal
                      </Link>
                      <Link
                        to="/beachcam/pt-ericeira-ribeira-dilhas"
                      >
                        Ribeira d'Ilhas - Ericeira
                      </Link>
                      <Link
                        to="/beachcam/carcavelos-tunnel"
                      >
                        Tunnel - Carcavelos
                      </Link>
                      <Link
                        to="/beachcam/pt-caparica-santo-antonio"
                      >
                        {" "}
                        Santo António - Caparica{" "}
                      </Link>
                      <Link
                        to="/beachcam/caparica-tarquinio"
                      >
                        {" "}
                        Tarquínio - Caparica{" "}
                      </Link>
                      <Link
                        to="/beachcam/nova-praia"
                      >
                        {" "}
                        Nova Praia - Caparica{" "}
                      </Link>
                      <Link
                        to="/beachcam/caparica-fonte-da-telha"
                      >
                        {" "}
                        Swara - Fonte da Telha{" "}
                      </Link>
                      <Link
                        to="/beachcam/aljezur-arrifana"
                      >
                        {" "}
                        Arrifana - Aljezur{" "}
                      </Link></>}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {inBrazilRegion ? (
            <NavbarItem
              title="preços"
              link=""
              handleClick={() => handleClick("price")}
            />
          ) : (
            <NavbarItem
              title="pricing"
              link=""
              handleClick={() => handleClick("price")}
            />
          )}
          <div className="profileDropdown" ref={dropdownRef1}>
            <div className="HeaderDropdown">
              <span onClick={toggleDropdownOnbording}>
                <FaRegUser className="icon" /> <IoIosArrowDown />
              </span>
              <div className="dropdownMenu">
                {isOpenOnbording ? (
                  <>
                    {inBrazilRegion ? (
                      <div className="dropdownBox">
                        <Link
                          onClick={() => {
                            authHandler();
                            setIsOpenOnbording(false);
                          }}
                        >
                          {" "}
                          Conecte-se{" "}
                        </Link>
                        <Link
                          onClick={() => {
                            authHandler(true);
                            setIsOpenOnbording(false);
                          }}
                        >
                          {" "}
                          Registrar{" "}
                        </Link>
                      </div>
                    ) : (
                      <div className="dropdownBox">
                        <Link
                          onClick={() => {
                            authHandler();
                            setIsOpenOnbording(false);
                          }}
                        >
                          {" "}
                          Login{" "}
                        </Link>
                        <Link
                          onClick={() => {
                            authHandler(true);
                            setIsOpenOnbording(false);
                          }}
                        >
                          {" "}
                          Register{" "}
                        </Link>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="navbar__menu">
          {inBrazilRegion ? (
            <Hamburger
              handleSetLocationReference={handleClick}
              handleRegionItemClick={handleRegionItemClick}
              authHandler={authHandler}
              links={[
                { title: "Como funciona" },
                { title: "Nossas Piscinas" },
                { title: "Localizações" },
                { title: "Preços" },
              ]}
            />
          ) : (
            <Hamburger
              handleSetLocationReference={handleClick}
              handleRegionItemClick={handleRegionItemClick}
              authHandler={authHandler}
              links={[
                { title: "How it works" },
                { title: "Our Pools" },
                { title: "Beachcams" },
                { title: "pricing" },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
