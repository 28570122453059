import styles from "./popup.module.css";
import { inBrazilRegion } from '../../utils/region';

const DayPassPopup = ({handleClick}) => {
    return (
        <>
        <div className={styles.ModalStart}>
                <div className={styles.background}></div>
                <div className={styles.BackGroundChnage}>
                <div className={styles.ModalBox}>
                    <div className={styles.Heading}>
                    <h2>{inBrazilRegion ? "SEU PASSE DIÁRIO ESTÁ ATIVO AGORA!" : "YOUR DAY PASS IS NOW ACTIVE!"}</h2>
                    </div>
                    <div className={styles.Box}>
                    <div className={styles.boxText}>
                        <h3>{inBrazilRegion ? "VOCÊ TEM 24 HORAS PARA SELECIONAR MAIS VÍDEOS DO DIA QUE ESCOLHEU." :"YOU HAVE 24 HOURS TO SELECT MORE VIDEOS FROM THE DAY YOU SELECTED."}</h3>
                    </div>
                    <div className={styles.boxButton}>
                        <button className={styles.whiteColor} onClick={() => handleClick(false)}>OK</button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </>

    )
}

export default DayPassPopup;