import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useWaveContext } from "../../../context/CartContext";
import { useActor } from "@xstate/react";
import styles from "./TimeAndPlaceSelector.module.css";
import watchStyles from "../../../pages/AppLayout.module.css";
import cardMart from "../../../assets/cardMart.svg";
import styles4 from "../Checkout/Checkout.module.css";
import { inBrazilRegion } from "../../../utils/region";

const availableTimes = () => {
  const times = [];
  for (let i = 7; i < 21; i++) {
    const formatedHour = i < 10 ? `0${i}` : i;
    times.push(`${formatedHour}`);
  }
  return times;
};

type TimeButtonParams = {
  time: string;
  onClick?: () => void;
};

const TimeButton = ({ time, onClick }: TimeButtonParams) => {
  return (
    <Button
      className={`${styles.date_time_button} ${styles.btnColorChange}`}
      variant="contained"
      disableElevation
      onClick={() => {
        if (onClick != undefined) {
          onClick();
        }
      }}
    >
      {time}
    </Button>
  );
};
export const TimeSelector = () => {
  const navigate = useNavigate();
  const [backButtonPopup, setBackButtonPopup] = useState(false);
  const { waveService } = useWaveContext();
  const [current] = useActor(waveService);

  // useEffect(() => {
  // waveService.send({
  //     type: 'reset'
  // });
  // }, []);

  // useEffect(() => {
  //     const handlePopstate = (event) => {
  //       console.log("hello handlePopstate--------------", event.type)
  //         if(event.type && event.type === 'popstate'){
  //         // Handle the back button press here
  //         setBackButtonPopup(true)
  //         alert('You pressed the browser back button!');
  //         // You can also change the route if needed
  //         // navigate("/cart")
  //       }
  //     };

  //     window.addEventListener('popstate',handlePopstate);

  //     return () => {
  //       setTimeout(() => {
  //         window.removeEventListener('popstate',handlePopstate);
  //         console.log('Event listener removed');
  //       }, 0)

  //     };
  //   }, []);
  const waveItems = JSON.parse(localStorage.getItem("waves")) || {};
  const selectedWavesData = Object.keys(waveItems).length;
  return (
    <div className={watchStyles.watch}>
      <div
        className={`${watchStyles.watchContainer}`}
        style={{ marginBottom: "46px" }}
      >
        <div className={styles.HeadingContainer}>
          <Button
            className={`${styles["button-title-btn"]} ${styles.HeaderFirst}`}
            onClick={() => {
              if (selectedWavesData <= 0) {
                localStorage.removeItem("waves");
                localStorage.removeItem("location");
                localStorage.removeItem("date");
                navigate(-1);
              } else {
                setBackButtonPopup(true);
                // navigate(-1)
              }
            }}
          >
            <MdOutlineArrowBackIosNew />
            {inBrazilRegion ? "Dia" : "Day"}

          </Button>
          <h2 className={styles.header}>
            {inBrazilRegion
              ? "QUANDO começou sua sessão?"
              : "WHEN DID YOUR SESSION START?"}
          </h2>
          <div className={styles.HeaderLast}></div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {availableTimes()
              .slice(0, 7)
              .map((time, idx) => (
                <TimeButton
                  key={"tdb" + idx}
                  time={`${time}:00`}
                  onClick={() => {
                    navigate(`${time}`);
                  }}
                />
              ))}
          </Grid>
          <Grid item xs={12} md={6} className={styles.topSpace}>
            {availableTimes()
              .slice(7, 14)
              .map((time, idx) => (
                <TimeButton
                  key={"tdb" + idx}
                  time={`${time}:00`}
                  onClick={() => {
                    navigate(`${time}`);
                  }}
                />
              ))}
          </Grid>
        </Grid>

        <div className={styles.wavesSeclect}>
          <button className={styles.btn} onClick={() => navigate("/cart")}>
            <img src={cardMart} alt="" />
            <span>{selectedWavesData}</span>
            {inBrazilRegion ? "ONDAS" : "WAVES"} <b>  {!inBrazilRegion && "SELECTED"}</b>
          </button>
        </div>
      </div>

      {backButtonPopup == true && selectedWavesData > 0 ? (
        <div className={`${styles4.modal}`}>
          <div className={`${styles4.blur}`}></div>
          <div className={`${styles4.boxWhite}`}>
            <h2>ARE YOU SURE?</h2>
            <div className={`${styles4.bgColor}`}>
              <h3>YOU WILL LOSE THE WAVES YOU'VE SELECTED, IF ANY</h3>
              <div className={`${styles4.btnAll}`}>
                <a
                  className={`${styles4.btn}`}
                  onClick={() => {
                    waveService.send({
                      type: "reset",
                    });
                    localStorage.removeItem("waves");
                    localStorage.removeItem("location");
                    localStorage.removeItem("date");
                    navigate(-1);
                  }}
                >
                  YES
                </a>
                <a
                  className={`${styles4.btn} ${styles4.active}`}
                  onClick={() => setBackButtonPopup(false)}
                >
                  NO
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
