import React from "react";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import "./Footer.styles.scss";
import FooterLogo from "../../assets/livro_reclamacoes-logo.png";
import { inBrazilRegion } from "../../utils/region";

const gitCommit = process.env.REACT_APP_GIT_COMMIT;
const gitBranch = process.env.REACT_APP_GIT_BRANCH;

const Footer = () => {
  return (
    <div className="footer">
      {inBrazilRegion ? (
        <>
          <div className="container footer-container">
            <div className="footer__contents">
              {!inBrazilRegion ? (
                <div className="footer__logo__ft footerSize">
                  <a
                    href="https://www.livroreclamacoes.pt/Inicio/"
                    target="_blank"
                  >
                    <img src={FooterLogo} alt="" />
                  </a>
                </div>
              ) : null}
              <div className="footer__contents__contact footerSize order-1">
                <div className="footer__contents__contact-heading">
                  Contate-nos :
                </div>
                <div className="footer__contents__contact-item">
                  <a
                    className="mobileNumber"
                    href="https://wa.me/5511942001651"
                    target="_blank"
                  >
                    +55 11 94200-1651
                  </a>
                </div>
                <div className="footer__contents__contact-item">
                  comercial@surfeye.video
                </div>
              </div>
              <div className="footer__contents__social footerSize order1">
                <div className="footer__contents__social-heading">Siga-nos</div>
                <div className="footer__contents__social-links">
                  {" "}
                  <div className="footer__contents__social-links-link">
                    <a
                      href={
                        inBrazilRegion
                          ? "https://www.instagram.com/surfeye.video.br"
                          : "https://www.instagram.com/surfeye.video/"
                      }
                      target="blank"
                    >
                      <FaInstagram />
                    </a>
                  </div>{" "}
                  <div className="footer__contents__social-links-link">
                    <a
                      href="https://www.youtube.com/channel/UC0O_3FymeiaTthft-7j8qVA"
                      target="blank"
                    >
                      <FaYoutube />
                    </a>
                  </div>
                  <div className="footer__contents__social-links-link">
                    <a href="https://vm.tiktok.com/ZMFFJEYGw/" target="blank">
                      {" "}
                      <FaTiktok />
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer__contents__companyinfo footerSize">
                <div className="footer__contents__companyinfo-heading">
                  Surf Eye Lda.
                </div>
                <div className="footer__contents__companyinfo-item">
                  NIF: 517387573
                </div>
                <div className="footer__contents__companyinfo-item">
                  Costa do Castelo 45
                </div>
                <div className="footer__contents__companyinfo-item">
                  Lisboa 1100-179 Portugal
                </div>
              </div>
            </div>
          </div>
          <div className="bottomFooter">
            <div className="container">
              <div className="footer-links">
                <div className="footer-links__link">
                  <a
                    href="https://www.surfeye.video/termsandconditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Termos e Condições
                  </a>
                </div>
                <div className="footer-links__link">
                  <a
                    href="https://www.surfeye.video/privacypolicy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    política de Privacidade
                  </a>
                </div>
                <div className="footer-links__link">
                  <a
                    href="https://www.iubenda.com/privacy-policy/56494368/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Política de Cookies
                  </a>
                </div>
                <div className="footer-links__link">ⓒ 2024 Surf Eye</div>
              </div>
              {gitCommit ? (
                <>
                  <div style={{ display: "none" }}>
                    Git commit: {gitCommit.substring(0, 8)}, branch: {gitBranch}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container footer-container">
            <div className="footer__contents">
              <div className="footer__logo__ft footerSize">
                <a
                  href="https://www.livroreclamacoes.pt/Inicio/"
                  target="_blank"
                >
                  <img src={FooterLogo} alt="" />
                </a>
              </div>
              <div className="footer__contents__contact footerSize order-1">
                <div className="footer__contents__contact-heading">
                  Contact us :
                </div>
                <div className="footer__contents__contact-item">
                  <a
                    className="mobileNumber"
                    href="https://wa.me/351934673643"
                    target="_blank"
                  >
                    +351 934673643
                  </a>
                </div>
                <div className="footer__contents__contact-item">
                  info@surfeye.video
                </div>
              </div>
              <div className="footer__contents__social footerSize order1">
                <div className="footer__contents__social-heading">
                  Follow us
                </div>
                <div className="footer__contents__social-links">
                  {" "}
                  <div className="footer__contents__social-links-link">
                    <a
                      href={
                        inBrazilRegion
                          ? "https://www.instagram.com/surfeye.video.br"
                          : "https://www.instagram.com/surfeye.video/"
                      }
                      target="blank"
                    >
                      <FaInstagram />
                    </a>
                  </div>{" "}
                  <div className="footer__contents__social-links-link">
                    <a
                      href="https://www.youtube.com/channel/UC0O_3FymeiaTthft-7j8qVA"
                      target="blank"
                    >
                      <FaYoutube />
                    </a>
                  </div>
                  <div className="footer__contents__social-links-link">
                    <a href="https://vm.tiktok.com/ZMFFJEYGw/" target="blank">
                      {" "}
                      <FaTiktok />
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer__contents__companyinfo footerSize">
                <div className="footer__contents__companyinfo-heading">
                  Surf Eye Lda.
                </div>
                <div className="footer__contents__companyinfo-item">
                  NIF: 517387573
                </div>
                <div className="footer__contents__companyinfo-item">
                  Costa do Castelo 45
                </div>
                <div className="footer__contents__companyinfo-item">
                  Lisboa 1100-179 Portugal
                </div>
              </div>
            </div>
          </div>
          <div className="bottomFooter">
            <div className="container">
              <div className="footer-links">
                <div className="footer-links__link">
                  <a
                    href="https://www.surfeye.video/termsandconditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and conditions
                  </a>
                </div>
                <div className="footer-links__link">
                  <a
                    href="https://www.surfeye.video/privacypolicy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
                <div className="footer-links__link">
                  <a
                    href="https://www.iubenda.com/privacy-policy/56494368/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cookie Policy
                  </a>
                </div>
                <div className="footer-links__link">ⓒ 2024 Surf Eye</div>
              </div>
              {gitCommit ? (
                <>
                  <div style={{ display: "none" }}>
                    Git commit: {gitCommit.substring(0, 8)}, branch: {gitBranch}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
