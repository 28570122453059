import React from "react";
import { Compass } from "../../../components/SitePage/compass";
import { ForecastData } from "./types";
import { getMinutesAndSeconds } from "../../../_utils/get-minutes-and-seconds";
import { cn } from "../../../_theme/utils";
import { Badge } from "../../../components/SitePage/badge";
import { inBrazilRegion } from '../../../utils/region';

type ForecastTableProps = {
  dataSet: ForecastData[];
};

const colors: Record<string, string> = {
  today: "bg-brand-green text-brand-light",
  tomorrow: "bg-brand-orange text-brand-light",
  monday: "bg-brand-lemon-green text-brand-light",
  tuesday: "bg-brand-lemon-green text-brand-light",
  wednesday: "bg-brand-red-3 text-brand-light",
  thursday: "bg-brand-lemon-green text-brand-light",
  friday: "bg-brand-orange text-brand-light",
  saturday: "bg-brand-lemon-green text-brand-light",
  sunday: "bg-brand-orange text-brand-light",
};

const tableHeaders = inBrazilRegion ? ["Surfe(m)", "Swell", "Vento"] :  ["Surf(m)", "Swell", "Wind"];

export const ForecastTable = ({ dataSet }: ForecastTableProps) => {
  return (
    <div className="bg-white rounded-3xl md:rounded-brand-600 shadow-brand-800 mx-auto max-w-[62.5rem] pt-7 md:pt-8 px-6 pb-7 md:pb-10 flex justify-center">
      <div className="max-w-[50rem] w-full">
        <div className="flex items-center justify-between border-b border-brand-primary border-opacity-20 mx-7 md:mx-14">
          {tableHeaders.map((header) => (
            <span
              key={header}
              className="uppercase text-xs md:text-lg font-medium pb-3 md:pb-5"
            >
              {header}
            </span>
          ))}
        </div>
        <div className="py-1.5 space-y-1.5">
          {dataSet.map((data) => (
            <div key={data.label} className="flex items-center justify-between">
              <div className="basis-1/3 flex items-center justify-center relative">
                <Badge
                  className={`absolute left-0 text-[6px] md:text-xs rotated-badge-primary w-[57px] md:w-[87px] px-[18px] py-0.5 bg-brand-lemon-green ${[
                    colors[data.label],
                  ]}`}
                >
                  {data.label}
                </Badge>
                <ForecastSurfHeightMetrics surfHeight={data.surf} />
              </div>
              <ForecastSwellMetrics swell={data.swell} />
              <ForecastWindMetrics wind={data.wind} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

type ForecastSurfHeightMetricsProps = {
  surfHeight: ForecastData["surf"];
};
const ForecastSurfHeightMetrics = ({
  surfHeight,
}: ForecastSurfHeightMetricsProps) => {
  return (
    <div className="text-center basis-1/3 flex gap-2 items-center justify-center py-5 md:py-8 flex-1">
      <ForecastMetrics
        className="text-center"
        value={surfHeight.height}
        unit={surfHeight.unit}
      />
    </div>
  );
};

type ForecastSwellMetricsProps = {
  swell: ForecastData["swell"];
};
const ForecastSwellMetrics = ({ swell }: ForecastSwellMetricsProps) => {
  return (
    <div className="text-center flex gap-2 items-center justify-center">
      <ForecastMetrics
        value={getMinutesAndSeconds(swell.time).hours}
        unit="m"
      />
      <ForecastMetrics
        value={getMinutesAndSeconds(swell.time).minutes}
        unit="s"
      />
      <span className="">
        <Compass
          direction={swell.direction}
          className="gap-0 md:gap-0.5 "
          textClass="text-[9px] md:text-xs leading-none"
          iconClass="w-3 md:w-6"
        />
      </span>
    </div>
  );
};

type ForecastWindMetricsProps = {
  wind: ForecastData["wind"];
};
const ForecastWindMetrics = ({ wind }: ForecastWindMetricsProps) => {
  return (
    <div className="basis-1/3 md:pr-8 flex gap-2 items-center justify-end">
      <ForecastMetrics value={wind.speed} unit="kph" />
      <span className="">
        <Compass
          direction={wind.direction}
          className="gap-0 md:gap-0.5 "
          textClass="text-[9px] md:text-xs leading-none"
          iconClass="w-3 md:w-6"
        />
      </span>
    </div>
  );
};

type ForecastMetricsProps = {
  value: string | number;
  unit: string;
  className?: string;
};
const ForecastMetrics = ({ value, unit, className }: ForecastMetricsProps) => {
  return (
    <div className={cn(className)}>
      <span className="">
        <span className="text-brand-md md:text-2xl font-bold">{value}</span>
        <span className="lowercase text-brand-md md:text-2xl font-medium">
          {unit}
        </span>
      </span>
    </div>
  );
};
