import styles from "./popup.module.css";
import dayjs from "dayjs";
import { inBrazilRegion } from "../../utils/region";

const SubsPopup = ({ handleClick }) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const subsDate = localStorage.getItem("subsDate");
  const date = dayjs(subsDate, "YYYYMMDD").toDate();

  const ddd = new Date(date);
  const YYYY = ddd.getFullYear();
  const MM = monthNames[ddd.getMonth()];
  const DD = ddd.getDate();

  return (
    <>
      <div className={styles.ModalStart}>
        <div className={styles.background}></div>
        <div className={styles.ModalBox}>
          <div className={styles.Heading}>
            <h2>
              {inBrazilRegion
                ? "BEM-VINDO AO SURF EYE UNLIMITED!"
                : "WELCOME TO SURF EYE UNLIMITED!"}
            </h2>
          </div>
          <div className={styles.Box}>
            <div className={styles.boxText}>
              <h3>
                {inBrazilRegion
                  ? "AGORA VOCÊ PODE BAIXAR TODAS AS ONDAS DE TODAS AS NOSSAS CÂMERAS!"
                  : "YOU CAN NOW DOWNLOAD EVERY WAVE, FROM ALL OUR CAMERAS!"}
              </h3>
              <h4>
                {inBrazilRegion
                  ? `SUA ASSINATURA SERÁ RENOVADA AUTOMATICAMENTE EM ${MM.toUpperCase()} ${DD}, ${YYYY}.`
                  : `YOUR SUBSCRIPTION WILL AUTOMATICALLY RENEW ON ${MM.toUpperCase()} ${DD}, ${YYYY}.`}
                {/* OCTOBER 11, 2023. */}
              </h4>
              <h4>
                {inBrazilRegion
                  ? "SE QUISER CANCELAR, VISITE A PÁGINA “MINHA CONTA”."
                  : "IF YOU WANT TO CANCEL, VISIT THE “MY ACCOUNT” PAGE."}
              </h4>
            </div>
            <div className={styles.boxButton}>
              <button
                className={styles.whiteColor}
                onClick={() => handleClick(false)}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubsPopup;
