import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import VideoView from "../../components/LandingPage/VideoView/VideoView";
import Navbar from "../../components/Navbar/Navbar";
import ReactGA from "react-ga4";
import Box from "@mui/material/Box";
import { IoArrowForwardSharp } from "react-icons/io5";
import { MdArrowForwardIos, MdCancel } from "react-icons/md";
import { COUNTRY_CODE, endpoints, GOOGLE_MAP_KEY } from "../../Api/Api";
import { LocationArea } from "../../components/Modal/locationArea";

import { IoIosArrowDown } from "react-icons/io";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "./LandingPage.styles.scss";
import userList1 from "../../assets/LandingNew/userlistImg.svg";
import userList2 from "../../assets/LandingNew/userlistImg2.svg";
import userList3 from "../../assets/LandingNew/userlistImg3.svg";
import webimg from "../../assets/LandingNew/webImg.svg";
import book from "../../assets/LandingNew/book.svg";
import buyLater from "../../assets/LandingNew/buyLater.svg";
import download from "../../assets/LandingNew/downloadIcon.svg";
import poolsSkudin from "../../assets/LandingNew/boxPools1.svg";
import poolsSurfCentre from "../../assets/LandingNew/boxPools2.svg";
import poolsRif010 from "../../assets/LandingNew/boxPoolsRif.svg";
import poolsWakeparadise from "../../assets/LandingNew/boxPoolsWakeparadise.svg";
import poolsWellenwerk from "../../assets/LandingNew/boxPoolsWellenwerk.svg";

import camera1 from "../../assets/LandingNew/camera1.svg";
import camera2 from "../../assets/LandingNew/camera2.svg";
import camera3 from "../../assets/LandingNew/camera3.svg";
import POWERED from "../../assets/LandingNew/POWERED.svg";
import yoursession from "../../assets/LandingNew/yoursession.svg";
import selectPlan from "../../assets/LandingNew/selectPlan.svg";
import Video from "../../assets/LandingNew/Video.svg";
import whyvideo1 from "../../assets/LandingNew/whyvideo1.svg";
import whyvideo2 from "../../assets/LandingNew/whyvideo2.svg";
import whyvideo3 from "../../assets/LandingNew/whyvideo3.svg";
import priceIcon1 from "../../assets/LandingNew/priceIcon1.svg";
import priceIcon2 from "../../assets/LandingNew/priceIcon2.svg";
import priceIcon3 from "../../assets/LandingNew/priceIcon3.svg";
import priceIcon4 from "../../assets/LandingNew/priceIcon4.svg";
import giftArrow from "../../assets/LandingNew/giftArrow.svg";
import tabs1 from "../../assets/LandingNew/tabs1.png";
import tabs2 from "../../assets/LandingNew/tabs2.png";
import tabs3 from "../../assets/LandingNew/tabs3.png";
import tabs4 from "../../assets/LandingNew/tabs4.png";
import tabs5 from "../../assets/LandingNew/tabs5.png";
import WAVEmix from "../../assets/LandingNew/WAVEmix.png";
import MarkIcon from "../../assets/marker.png";

import faq1 from "../../assets/LandingNew/faq1.svg";
import faq2 from "../../assets/LandingNew/faq2.svg";
import faq3 from "../../assets/LandingNew/faq3.svg";
import whatsapp from "../../assets/LandingNew/whatsapp.svg";
import Email from "../../assets/LandingNew/Email_Icon_UIA.svg";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import axios from "axios";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { Tooltip } from "react-tooltip";
import { inBrazilRegion } from "../../utils/region";

ReactGA.initialize("G-9RWTM70QHK");
// Send pageview with a custom path
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
  title: "Landing Page1",
});
const countryCode = COUNTRY_CODE;

const LandingPage = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [Location, setLocation] = useState("region");
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const container = useRef();
  const slider = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let sections = gsap.utils.toArray(".panel");

    gsap.to(sections, {
      xPercent: -80 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".webviewscrollheight",
        pin: true,
        scrub: 0.1,
        end: () => "+=2000",
        markers: false,
        onUpdate: (self) => {
          const progress = self.progress;
          const totalSections = sections.length;
          const currentlySeenDiv =
            Math.round(progress * (totalSections - 1)) + 1;
          if (currentlySeenDiv > 5) {
            setValue(5);
          } else {
            setValue(currentlySeenDiv);
          }
        },
      },
    });
  }, []);

  const MAP_STYLES = [
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [{ color: "#ffffff" }, { lightness: 17 }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [{ color: "#ffffff" }, { lightness: 18 }],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [{ color: "#ffffff" }, { lightness: 16 }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#dedede" }, { lightness: 21 }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ visibility: "on" }, { color: "#ffffff" }, { lightness: 16 }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ saturation: 36 }, { color: "#333333" }, { lightness: 40 }],
    },
    { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [{ color: "#fefefe" }, { lightness: 20 }],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
    },
  ];

  // day, wave, unlimited
  const [pricing, setPricing] = useState("unlimited");
  const [targetLocationModalItem, setTargetLocationModalItem] = useState(null);
  const [pageState, setPageState] = useState({ lastMapScrollEvent: null });
  const [regionitem, setRegionItem] = useState(0);
  const [activeRegion, setActiveRegion] = useState([]);
  const [region, setRegion] = useState({});
  const [activeSpot, setActiveSpot] = useState([]);
  const [regionCenter, setRegionCenter] = useState({ lat: 0, lng: 0 });
  const [spotCenter, setSpotCenter] = useState({ lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = useState({ region: 11, spot: 11 });
  let lastMousePosition = null;
  const [tipInfo, setTipInfo] = useState({
    position: { x: 0, y: 0 },
    message: "",
    opened: false,
    animation: window?.google?.maps?.Animation?.BOUNCE ?? 0,
  });

  const getCoordinateOf = async (address, setter) => {
    try {
      const name = encodeURIComponent(address);
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${name}&key=${GOOGLE_MAP_KEY}`;
      const response = await axios.get(url);
      const result = response.data;
      console.debug("address:", address, "result:", result);
      if (result.status !== "OK") {
        return;
      }

      const match = result.results[0].geometry.location;
      console.debug("address:", address, "location: ", match);
      setter(match);
    } catch (error) {
      console.error("[LandingPage::getRegionCoordinate]");
    }
  };

  useEffect(() => {
    const getRegions = async () => {
      try {
        console.debug("Endpoints:", endpoints);
        const response = await axios.get(
          `${endpoints.GET_REGIONS}?country=${countryCode}`,
        );
        const result = response.data;
        if (result.status !== "success") {
          //TODO: Error handling (error from backend)
          return;
        }

        const ordered_regions = result.data.sort((one, other) => {
          return other.numberOfSpots - one.numberOfSpots;
        });

        console.debug("ordered_regions:", ordered_regions);

        const regions = ordered_regions.map((reg) => ({
          id: reg.id,
          name: reg.name,
          slug: reg.slug,
          spot: `${reg.numberOfSpots} SPOTS`,
          active: reg.numberOfSpots > 0,
          spots: reg.spots.map((spot) => ({
            coord: {
              lat: spot.locationLat,
              lng: spot.locationLng,
            },
            slug: spot.slug,
            name: spot.name,
          })),
        }));
        console.debug("Regions: ", regions);
        if (regions.length !== 0) {
          setActiveRegion(regions);
          setRegion(regions[0]);
          return regions[0];
        }
      } catch (error) {
        console.error("[LandingPage::getRegions]:", error);
      }
    };

    getRegions().then((top) => getCoordinateOf(top.name, setRegionCenter));
  }, []);

  const showSpotsModal = useCallback((region, spot, id) => {
    const partners = Object.values(spot.partners);
    const recordingArea = Object.values(spot.recordingArea);
    const spotItem = {
      id: id,
      title1: region.name,
      title2: spot.name,
      imgLocation: recordingArea?.[0].imageUrl ?? "",
      slug: spot.slug,
      imgSponsor: partners?.[0]?.imageUrl ?? "",
      googleLocationLink: spot.locationUrl,
    };
    setTargetLocationModalItem(spotItem);
    setSeeLocationModal(true);
  });

  const fetchSpots = async (slug) => {
    const response = await axios.get(
      `${endpoints.GET_SPOTS}?region=${slug}&country=${countryCode}`,
    );
    const result = response.data;
    if (result.status !== "success") {
      //TODO: Error handling (error from backend)
      return null;
    }

    const spots = result.data.map((locality, id) => {
      return {
        ...locality,
        id: id,
        name: locality.name,
        active: true,
        marker: { lat: +locality.locationLat, lng: +locality.locationLng },
        googleLocationLink: locality.locationUrl,
      };
    });

    console.debug(
      "requested spots:",
      spots,
      "result:",
      result.data,
      "slug:",
      slug,
    );

    return spots;
  };

  const handleRegionItemClick = useCallback((id, item) => {
    const getSpots = async (slug) => {
      const spots = (await fetchSpots(slug))?.map((locality, id) => {
        console.debug("locality:", locality);
        const partners = Object.values(locality.partners);
        return {
          ...locality,
          cmp: (
            <div className="boxCenter">
              <h3>{locality.name}</h3>
              {partners.length === 0 ? <></> : <p>{partners[0].name}</p>}
              <span onClick={() => showSpotsModal(region, locality, id)}>
                {inBrazilRegion ? "Ir ao pico" : "Check spot"}
              </span>
              {partners.length === 0 ? (
                <></>
              ) : (
                <img
                  className="bottomImage"
                  style={{ height: "50px", borderRadius: "8px" }}
                  src={partners[0].imageUrl}
                  alt=""
                />
              )}
            </div>
          ),
        };
      });

      console.debug("spots:", spots);
      if (spots.length !== 0) {
        setRegionItem(id);
        setRegion(item);
        setLocation("spot");
        setActiveSpot(spots);

        return item;
      }
    };

    getSpots(item.slug).then((top) => getCoordinateOf(top.name, setSpotCenter));
  });

  const updateMap = (event, owner, type) => {
    switch (type) {
      case "zoom":
        if (mapZoom[owner] !== event.detail.zoom) {
          setMapZoom((prev) => ({ ...prev, [owner]: event.detail.zoom }));
        }
        break;
      case "drag":
        console.debug("drag event:", event.map?.__proto__);
        break;
    }
    console.debug(type, event);

    // setMapBounds(prev => ({
    //   ...prev,
    //   [owner]: {
    //     east: event.detail.bounds.east,
    //     north: event.detail.bounds.north,
    //     south: event.detail.bounds.south,
    //     west: event.detail.bounds.west
    //   }
    // }));

    // console.debug('bounds:', mapBounds);
  };

  const showToolTip = (spot) => (e) => {
    const ARROW_OFFSET = 16;
    setTipInfo((prev) => ({
      ...prev,
      position: { x: e.domEvent.clientX, y: e.domEvent.clientY - ARROW_OFFSET },
      message: spot.name,
      opened: true,
    }));
  };

  const hideTooltip = () => {
    if (tipInfo.opened) {
      setTipInfo((prev) => ({ ...prev, opened: false, message: "" }));
    }
  };

  function checkTooltip(e) {
    const element = document.querySelector(".map");
    if (!element || e === null) {
      lastMousePosition = e;
      hideTooltip();
      return;
    }

    const { x, y, width, height } = element.getBoundingClientRect();
    if (
      e.clientX > x + width ||
      e.clientX < x ||
      e.clientY > y + height ||
      e.clientY < y
    ) {
      hideTooltip();
    }
    lastMousePosition = e;
  }

  const onSpotClick = (region, selection) => {
    fetchSpots(region.slug).then((spots) => {
      const search = spots.find((spot) => spot.slug === selection.slug);
      console.debug("spots:", spots, "search:", search);
      navigate(`/beachcam/${search.slug}`);
    });
  };

  useEffect(() => {
    const component = window.location.href.split("#")[1];
    if (component) {
      document
        .getElementById(component)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const options = {
    loop: false,
    dots: false,
    nav: true,
    margin: 22,
    navText: [
      `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`,
      `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`,
    ],
    autoplay: false,

    responsive: {
      0: {
        items: 2,
        dots: true,
        margin: 18,
      },
      413: {
        items: 2,
        dots: true,
        margin: 18,
      },
      768: {
        items: 3,
      },
      1024: {
        items: 3,
      },
      1200: {
        items: 4,
      },
      1500: {
        items: 4,
      },
    },
  };

  const [activeTab, setActiveTab] = useState(1);
  const listRefs = useRef(Array.from({ length: 5 }, () => React.createRef()));
  // console.debug(activeTab, "activeTab", listRefs)
  const switchTab = (e) => {
    const lis = listRefs.current;
    let currentTab = activeTab;
    let scroll = document.getElementsByClassName("tabScrollShow");
    lis.forEach((scroll, index) => {
      const rect = scroll.current.getBoundingClientRect();

      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        currentTab = index + 1;
      }
    });

    setActiveTab(currentTab);
  };

  useEffect(() => {
    const handleScroll = (e) => {
      const map = document.querySelector(".map");
      // console.debug('scrollEvent:', map.getBoundingClientRect());
      switchTab(e);
      checkTooltip(lastMousePosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeTab]);

  const [showRegisterPopup, setShowRegisterPopup] = useState(false);

  const [isAuth, setIsAuth] = useState(true);
  const authHandler = (args = false) => {
    if (args === true) {
      // handle condition for register popup
      setShowRegisterPopup(true);
    } else {
      setShowRegisterPopup(false);
    }
    setIsAuth(!isAuth);
  };

  const [seeLocationModal, setSeeLocationModal] = useState(false);
  const closeHandler = () => {
    setSeeLocationModal(!seeLocationModal);
  };

  const [item, setItem] = useState(0);
  const handleItemClick = (id) => {
    setItem(id);
    setActiveSpot((prev) => {
      return prev.map((previtem, index) => ({
        ...previtem,
        active: index === id,
      }));
    });
  };
  const headerRef = useRef({});
  const handleClick = (value) => {
    headerRef.current[value]?.scrollIntoView({ behavior: "smooth" });
  };

  const isAuthHandler = () => {
    const getToken = localStorage.getItem("token");
    const getUserInfo = localStorage.getItem("userInfo");
    if (getToken !== null) {
      if (
        JSON.parse(getUserInfo).role === "customer" ||
        JSON.parse(getUserInfo).role === "superadmin"
      ) {
        navigate("/session");
      }
    } else {
      authHandler();
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Surf Eye</title>
      </Helmet>
      <Navbar
        isHome={true}
        authHandler={authHandler}
        isAuth={isAuth}
        handleClick={handleClick}
        handleRegionItemClick={handleRegionItemClick}
      />
      <VideoView
        authHandler={authHandler}
        isAuth={isAuth}
        showRegisterPopup={showRegisterPopup}
      />
      <div className="landing-page-section howItWorks mhidden" id="finalID">
        <div className="container webviewscrollheight">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                className="hotItHeading"
                ref={(prev) => {
                  headerRef.current["howitworks"] = prev;
                }}
              >
                {inBrazilRegion ? (
                  <div>
                    <div className="topTitle">
                      <h2>COMO FUNCIONA</h2>
                      <h5>CONTINUE ROLANDO :)</h5>
                    </div>
                    <div className="stepsfive">
                      <h3>5 PASSOS</h3>
                      <h4>PARA OBTER OS SEUS VÍDEOS</h4>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="topTitle">
                      <h2>How it Works</h2>
                      <h5>keep scrolling :)</h5>
                    </div>
                    <div className="stepsfive">
                      <h3>5 steps</h3>
                      <h4>to get your videos</h4>
                    </div>
                  </div>
                )}
              </div>

              <div className="tabsAnimation">
                <div className="">
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={3}
                      style={{ justifyContent: "center" }}
                    >
                      <Grid item xs={12} md={12} lg={10}>
                        <div className="tabsNewDesign">
                          <div
                            className={`tabsTopDesign ${
                              value === 1 ? "active" : ""
                            }`}
                          >
                            <div className="round">
                              <img src={tabs1} alt="Tab 1" /> <span>1</span>
                            </div>
                            <div className="tabsHeight">
                              {inBrazilRegion ? <b>surfe</b> : <b>surf</b>}
                            </div>
                          </div>
                          <div
                            className={`tabsTopDesign ${
                              value === 2 ? "active" : ""
                            }`}
                          >
                            <div className="round">
                              <img src={tabs2} alt="Tab 2" /> <span>2</span>
                            </div>
                            <div className="tabsHeight">
                              {" "}
                              {inBrazilRegion ? (
                                <b>
                                  CRIE UMA<br></br>CONTA
                                </b>
                              ) : (
                                <b>
                                  create an <br></br> account
                                </b>
                              )}
                            </div>
                          </div>
                          <div
                            className={`tabsTopDesign ${
                              value === 3 ? "active" : ""
                            }`}
                          >
                            <div className="round">
                              <img src={tabs3} alt="Tab 3" /> <span>3</span>
                            </div>
                            <div className="tabsHeight">
                              <b>
                                {" "}
                                {inBrazilRegion ? (
                                  <b>
                                    ENCONTRE<br></br>SUAS ONDAS
                                  </b>
                                ) : (
                                  <b>
                                    find your <br></br> waves
                                  </b>
                                )}
                              </b>
                            </div>
                          </div>
                          <div
                            className={`tabsTopDesign ${
                              value === 4 ? "active" : ""
                            }`}
                          >
                            <div className="round">
                              <img src={tabs4} alt="Tab 4" /> <span>4</span>
                            </div>
                            <div className="tabsHeight">
                              {inBrazilRegion ? (
                                <b>
                                  FINALIZE A<br></br>COMPRA
                                </b>
                              ) : (
                                <b>check out</b>
                              )}
                            </div>
                          </div>
                          <div
                            className={`tabsTopDesign ${
                              value === 5 ? "active" : ""
                            }`}
                          >
                            <div className="round">
                              <img src={tabs5} alt="Tab 5" /> <span>5</span>
                            </div>
                            <div className="tabsHeight">
                              <b>download</b>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Box>
                      <div>
                        <div className="container tabscontainer">
                          {value === 1 && (
                            <section className="panel blue">
                              <div>
                                <pre className="code-block prettyprint lang-js linenums">
                                  <div className="tabsOne">
                                    <Grid
                                      container
                                      spacing={3}
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Grid item xs={5} md={5} lg={3}>
                                        {inBrazilRegion ? (
                                          <div className="content">
                                            <h3>
                                              Surfe dentro da área de gravaçã{" "}
                                              <br></br> de uma das nossas
                                              localizações
                                            </h3>
                                            <h4>
                                              Nossa câmera grava automaticamente{" "}
                                              <br></br> todos os surfistas
                                            </h4>
                                            <button
                                              className="btn"
                                              onClick={() =>
                                                handleClick("location")
                                              }
                                            >
                                              Ver localizações
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="content">
                                            <h3>
                                              go to a surf eye spot and{" "}
                                              <br></br> surf within the
                                              recording area
                                            </h3>
                                            <h4>
                                              Our camera will automatically{" "}
                                              <br></br> record every surfer
                                            </h4>
                                            <button
                                              className="btn"
                                              onClick={() =>
                                                handleClick("location")
                                              }
                                            >
                                              see locations
                                            </button>
                                          </div>
                                        )}
                                      </Grid>
                                      <Grid item xs={7} md={7} lg={5}>
                                        <Grid
                                          item
                                          xs={12}
                                          md={11}
                                          style={{ margin: "0 auto" }}
                                        >
                                          <div className="tobsBottom">
                                            <img src={POWERED} alt="" />
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </pre>
                              </div>
                              <div className="box-1 box">box-1</div>
                            </section>
                          )}
                        </div>
                        <div className="container tabscontainer">
                          {value === 2 && (
                            <section className="panel red">
                              <div>
                                <pre className="code-block prettyprint lang-js linenums">
                                  <div className="tabsOne">
                                    <Grid
                                      container
                                      spacing={3}
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Grid item xs={5} md={5} lg={3}>
                                        {inBrazilRegion ? (
                                          <div className="content">
                                            <h3>
                                              Entre na plataforma até 7 dias
                                              depois <br></br> da sessão
                                            </h3>
                                            <h4>
                                              Seus vídeos estarão disponíveis 30{" "}
                                              <br></br> minutos após a sua
                                              sessão
                                            </h4>
                                            <button
                                              className="btn"
                                              onClick={authHandler}
                                            >
                                              Registe-se agora
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="content">
                                            <h3>
                                              or login within 7 days after{" "}
                                              <br></br> your session
                                            </h3>
                                            <h4>
                                              your videos will be available 30{" "}
                                              <br></br> minutes after your
                                              session
                                            </h4>
                                            <button
                                              className="btn"
                                              onClick={authHandler}
                                            >
                                              join Now
                                            </button>
                                          </div>
                                        )}
                                      </Grid>
                                      <Grid item xs={7} md={7} lg={5}>
                                        <Grid
                                          item
                                          xs={12}
                                          md={11}
                                          style={{ margin: "0 auto" }}
                                        >
                                          <div className="tobsBottom">
                                            <img src={yoursession} alt="" />
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </pre>
                              </div>
                              <div className="box-1 box">box-1</div>
                            </section>
                          )}
                        </div>
                        <div className="container tabscontainer">
                          {value === 3 && (
                            <section className="panel gray">
                              <div>
                                <pre className="code-block prettyprint lang-js linenums">
                                  <div className="tabsOne">
                                    <Grid
                                      container
                                      spacing={3}
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Grid item xs={5} md={5} lg={3}>
                                        {inBrazilRegion ? (
                                          <div className="content">
                                            <h3>
                                              Assista às prévias e selecione{" "}
                                              <br></br> suas ondas
                                            </h3>
                                            <button
                                              className="btn"
                                              onClick={isAuthHandler}
                                            >
                                              Assista agora
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="content">
                                            <h3>
                                              Watch the previews and select{" "}
                                              <br></br> your waves
                                            </h3>
                                            <button
                                              className="btn"
                                              onClick={isAuthHandler}
                                            >
                                              Watch Now
                                            </button>
                                          </div>
                                        )}
                                      </Grid>
                                      <Grid item xs={7} md={7} lg={5}>
                                        <Grid
                                          item
                                          xs={12}
                                          md={11}
                                          style={{ margin: "0 auto" }}
                                        >
                                          <div className="tobsBottom">
                                            <img src={Video} alt="" />
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </pre>
                              </div>
                              <div className="box-2 box">box-2</div>
                            </section>
                          )}
                        </div>
                        <div className="container tabscontainer">
                          {value === 4 && (
                            <section className="panel purple">
                              <div>
                                <pre className="code-block prettyprint lang-js linenums">
                                  <div className="tabsOne">
                                    <Grid
                                      container
                                      spacing={3}
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Grid item xs={5} md={5} lg={3}>
                                        {inBrazilRegion ? (
                                          <div className="content">
                                            <h3>
                                              {" "}
                                              pague por onda ou selecione um
                                              plano
                                            </h3>
                                            <h4>
                                              Aceitamos a maioria dos cartões de
                                              crédito <br></br> e cartões de
                                              débito
                                            </h4>
                                            <button
                                              className="btn"
                                              onClick={() =>
                                                handleClick("price")
                                              }
                                            >
                                              ver Planos
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="content">
                                            <h3>
                                              {" "}
                                              pay by wave or select a plan
                                            </h3>
                                            <h4>
                                              We accept the majority of credit{" "}
                                              <br></br> and debit cards
                                            </h4>
                                            <button
                                              className="btn"
                                              onClick={() =>
                                                handleClick("price")
                                              }
                                            >
                                              see Plans
                                            </button>
                                          </div>
                                        )}
                                      </Grid>
                                      <Grid item xs={7} md={7} lg={5}>
                                        <Grid
                                          item
                                          xs={12}
                                          md={11}
                                          style={{ margin: "0 auto" }}
                                        >
                                          <div className="tobsBottom">
                                            <img src={selectPlan} alt="" />
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </pre>
                              </div>
                              <div className="box-3 box">box-3</div>
                            </section>
                          )}
                        </div>
                        <div className="container tabscontainer" id="OtherID">
                          {value === 5 && (
                            <section className="panel green">
                              <div>
                                <pre className="code-block prettyprint lang-js linenums">
                                  <div className="tabsOne">
                                    <Grid
                                      container
                                      spacing={3}
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Grid item xs={5} md={5} lg={3}>
                                        {inBrazilRegion ? (
                                          <div className="content">
                                            <h3>
                                              ANALISE SEU SURF E<br></br>
                                              COMPARTILHE COM OS AMIGOS
                                            </h3>
                                            <button
                                              className="btn"
                                              onClick={isAuthHandler}
                                            >
                                              Assista agora
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="content">
                                            <h3>
                                              ANALYZE YOUR SURFING AND <br></br>{" "}
                                              SHARE WITH FRIENDS
                                            </h3>
                                            <button
                                              className="btn"
                                              onClick={isAuthHandler}
                                            >
                                              Watch Now
                                            </button>
                                          </div>
                                        )}
                                      </Grid>
                                      <Grid item xs={7} md={7} lg={5}>
                                        <Grid
                                          item
                                          xs={12}
                                          md={11}
                                          style={{ margin: "0 auto" }}
                                        >
                                          <div className="tobsBottom">
                                            {/* <img src={VideoTabs} alt="" /> */}
                                            <video
                                              muted
                                              loop
                                              autoPlay
                                              playsInline
                                            >
                                              <source
                                                src="https://www.surfeye.video/web/LANDING-PAGE-SECTION-4_v3_compressed.mp4"
                                                type="video/mp4"
                                              />
                                            </video>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </pre>
                              </div>
                            </section>
                          )}
                        </div>

                        <div className="container tabscontainer">
                          <section className="panel blue "></section>
                          <section className="panel red stopAnimation"></section>
                          <section className="panel gray "></section>
                          <section className="panel purple stopAnimation"></section>
                          <section className="panel green "></section>
                        </div>

                        <div className="final">
                          <div>
                            <h1>Wasn't that fun?</h1>
                            <p>Here are a few caveats to keep in mind:</p>
                            <ul>
                              <li>
                                The fake-scrolling animation (just the part
                                that's moving the container horizontally) must
                                have no easing (<code>ease: "none"</code>).
                              </li>
                              <li>
                                Pinning and snapping won't work on
                                ScrollTriggers with a{" "}
                                <code>containerAnimation</code>.
                              </li>
                              <li>
                                The mapping of scroll position trigger points
                                are based on the trigger element itself not
                                being animated horizontally (inside the
                                container). If you need to animate the trigger,
                                you can either wrap it in a &lt;div&gt; and use
                                that as the trigger instead or just factor the
                                trigger's movement into your end position. For
                                example, if you animate it left 100px, make the{" "}
                                <code>end</code> 100px further to the left.
                              </li>
                              <li>Requires ScrollTrigger 3.8.0 or later</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </div>

                <div className="tabsBottomButton">
                  <Link href="/?section=login">
                    <button className="btn" onClick={authHandler}>
                      {inBrazilRegion ? (
                        <b>COMECE AGORA</b>
                      ) : (
                        <b>Get Started</b>
                      )}
                    </button>
                  </Link>
                  {inBrazilRegion ? (
                    <b>PRECISA DE MAIS INFORMAÇÕES?</b>
                  ) : (
                    <b>do you need more info?</b>
                  )}
                  <h5></h5>
                  <div onClick={() => setIsFaqOpen(true)}>
                    <Link href="#">
                      {inBrazilRegion ? <b>Veja aqui</b> : <b>Click here</b>}
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="joinBox">
            <div className="container">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div className="boxDesign">
                    <div className="userList">
                      <div>
                        {inBrazilRegion ? (
                          <span>Junte-se a </span>
                        ) : (
                          <span>join our </span>
                        )}
                        <div className="userImg1">
                          <img src={userList1} alt="" />
                          <img src={userList2} alt="" />
                          <img src={userList3} alt="" />
                        </div>
                        <div className="userPlus">
                          <h3>14.000+</h3>
                          {inBrazilRegion ? (
                            <h5>utilizadores</h5>
                          ) : (
                            <h5>users</h5>
                          )}
                        </div>
                      </div>
                      <div
                        className="userImg"
                        style={{ transform: "translate(40px, 0px)" }}
                      >
                        <img src={userList1} alt="" />
                        <img src={userList2} alt="" />
                        <img src={userList3} alt="" />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="boxDesign">
                    {inBrazilRegion ? (
                      <div className="userList">
                        <div>
                          <span>e mais de</span>
                          <div className="userImg1 imgSize">
                            <img src={WAVEmix} alt="" />
                          </div>
                          <div className="userPlus">
                            <h3>5.2m+</h3>
                            <h5>ondas Filmadas</h5>
                          </div>
                        </div>
                        <div className="userImg imgSize">
                          <img src={WAVEmix} alt="" />
                        </div>
                      </div>
                    ) : (
                      <div className="userList">
                        <div>
                          <span>and more than</span>
                          <div className="userImg1 imgSize">
                            <img src={WAVEmix} alt="" />
                          </div>
                          <div className="userPlus">
                            <h3>5.2m+</h3>
                            <h5>waves recorded</h5>
                          </div>
                        </div>
                        <div className="userImg imgSize">
                          <img src={WAVEmix} alt="" />
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="videosAnywhare">
            <div className="container">
              <Grid container>
                <Grid item xs={12} md={9} style={{ margin: "0 auto" }}>
                  <div className="titleHeading">
                    {inBrazilRegion ? (
                      <h3>
                        assista e compartilhe seus <br></br> vídeos em qualquer
                        lugar
                      </h3>
                    ) : (
                      <h3>
                        watch and share your <br></br> videos anywhere
                      </h3>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="videoBox">
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {inBrazilRegion ? (
                          <div className="subHeading">
                            <h5>Seja gravado</h5>
                            <p>A qualquer dia. A qualquer hora.</p>
                          </div>
                        ) : (
                          <div className="subHeading">
                            <h5>get recorded</h5>
                            <p>Any day. at any time.</p>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12} lg={10}>
                        <div className="videoModal">
                          <div className="imgweb">
                            <div className="laptopVideoMargeWeb">
                              <img className="webimg" src={webimg} alt="" />
                              <video muted loop autoPlay playsInline>
                                <source
                                  src="https://www.surfeye.video/web/LANDING-PAGE-SECTION-4_v3_compressed.mp4"
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                            <div className="laptopVideoMargeMobile">
                              {/* <img className="mobileimg" src={mobileimg} alt="" /> */}
                              <video muted loop autoPlay playsInline>
                                <source
                                  src="https://www.surfeye.video/web/SE_LP_new_mobile_site_v.2.mp4"
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          </div>
                          {inBrazilRegion ? (
                            <ul>
                              <li>
                                <span>
                                  <img src={book} alt="" />
                                </span>
                                <span>
                                  sempre ligado. <br></br> não precisa Agendar
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img src={buyLater} alt="" />
                                </span>
                                <span>
                                  surfe primeiro <br></br> compre depois
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img src={download} alt="" />
                                </span>
                                <span>
                                  baixe <br></br> onda por onda
                                </span>
                              </li>
                            </ul>
                          ) : (
                            <ul>
                              <li>
                                <span>
                                  <img src={book} alt="" />
                                </span>
                                <span>
                                  always on. <br></br> no need to book
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img src={buyLater} alt="" />
                                </span>
                                <span>
                                  surf first <br></br> buy later
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img src={download} alt="" />
                                </span>
                                <span>
                                  download <br></br> wave by wave
                                </span>
                              </li>
                            </ul>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div
          className="marginTopHighLight"
          ref={(prev) => {
            headerRef.current["highlight"] = prev;
          }}
        ></div>
      </div>

      <div
        className="howItWorks dhidden"
        ref={(prev) => {
          headerRef.current["howitworksmobile"] = prev;
        }}
      >
        <div className="container webviewscrollheight">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {inBrazilRegion ? (
                <div className="hotItHeading">
                  <div className="topTitle">
                    <h2>Como funciona</h2>
                    <h5>continue rolando :)</h5>
                  </div>
                  <div className="stepsfive">
                    <h3>5 passos</h3>
                    <h4>para obter seus vídeos</h4>
                  </div>
                </div>
              ) : (
                <div className="hotItHeading">
                  <div className="topTitle">
                    <h2>How it Works</h2>
                    <h5>keep scrolling :)</h5>
                  </div>
                  <div className="stepsfive">
                    <h3>5 steps</h3>
                    <h4>to get your videos</h4>
                  </div>
                </div>
              )}

              <div className="tabsAnimation">
                <div className="">
                  <div className="mobildeTabs">
                    <ul className="tabsContainer">
                      {inBrazilRegion ? (
                        <li
                          className={`right ${activeTab === 1 ? "active" : ""}`}
                          ref={listRefs.current[0]}
                        >
                          <button className="tabsButton">
                            <b>1</b>
                            <img src={tabs1} alt="Tab 1" />
                          </button>
                          <span className="title">surf</span>

                          <div className="tabScrollShow">
                            <h3>
                              em um local de surf e <br></br> surfar dentro da
                              area de gravaçao
                            </h3>
                            <img src={POWERED} alt="" />
                            <h4>
                              Nossa câmera gravará automaticamente <br></br>{" "}
                              todo surfista
                            </h4>
                            <button
                              className="btn"
                              onClick={() => {
                                handleClick("location");
                              }}
                            >
                              ver locais
                            </button>
                          </div>
                        </li>
                      ) : (
                        <li
                          className={`right ${activeTab === 1 ? "active" : ""}`}
                          ref={listRefs.current[0]}
                        >
                          <button className="tabsButton">
                            <b>1</b>
                            <img src={tabs1} alt="Tab 1" />
                          </button>
                          <span className="title">surf</span>

                          <div className="tabScrollShow">
                            <h3>
                              go to a surf eye spot and <br></br> surf within
                              the recording area
                            </h3>
                            <img src={POWERED} alt="" />
                            <h4>
                              Our camera will automatically record <br></br>{" "}
                              every surfer
                            </h4>
                            <button
                              className="btn"
                              onClick={() => {
                                handleClick("location");
                              }}
                            >
                              see locations
                            </button>
                          </div>
                        </li>
                      )}
                      {inBrazilRegion ? (
                        <li
                          className={`left ${activeTab === 2 ? "active" : ""}`}
                          ref={listRefs.current[1]}
                        >
                          <button className="tabsButton">
                            <b>2</b>
                            <img src={tabs2} alt="Tab 1" />
                          </button>
                          <span className="title">
                            CRIAR UM <br></br> Conta
                          </span>

                          <div className="tabScrollShow">
                            <h3>
                              ou faça login dentro de 7 dias após <br></br> sua
                              sessão
                            </h3>
                            <img src={yoursession} alt="" />
                            <h4>
                              seus vídeos estarão disponíveis 30<br></br>{" "}
                              minutos após sua sessão
                            </h4>
                            <button className="btn" onClick={authHandler}>
                              Junte-se agora
                            </button>
                          </div>
                        </li>
                      ) : (
                        <li
                          className={`left ${activeTab === 2 ? "active" : ""}`}
                          ref={listRefs.current[1]}
                        >
                          <button className="tabsButton">
                            <b>2</b>
                            <img src={tabs2} alt="Tab 1" />
                          </button>
                          <span className="title">
                            CREATE AN <br></br> Account
                          </span>

                          <div className="tabScrollShow">
                            <h3>
                              or login within 7 days after <br></br> your
                              session
                            </h3>
                            <img src={yoursession} alt="" />
                            <h4>
                              your videos will be available 30 <br></br> minutes
                              after your session
                            </h4>
                            <button className="btn" onClick={authHandler}>
                              join now
                            </button>
                          </div>
                        </li>
                      )}
                      {inBrazilRegion ? (
                        <li
                          className={`right ${activeTab === 3 ? "active" : ""}`}
                          ref={listRefs.current[2]}
                        >
                          <button className="tabsButton">
                            <b>3</b>
                            <img src={tabs3} alt="Tab 1" />
                          </button>
                          <span className="title">
                            encontre seu <br></br> ondas
                          </span>

                          <div className="tabScrollShow">
                            <h3>Assista às prévias e selecione </h3>
                            <img src={Video} alt="" />
                            <button className="btn" onClick={isAuthHandler}>
                              Assista agora
                            </button>
                          </div>
                        </li>
                      ) : (
                        <li
                          className={`right ${activeTab === 3 ? "active" : ""}`}
                          ref={listRefs.current[2]}
                        >
                          <button className="tabsButton">
                            <b>3</b>
                            <img src={tabs3} alt="Tab 1" />
                          </button>
                          <span className="title">
                            find your <br></br> waves
                          </span>

                          <div className="tabScrollShow">
                            <h3>Watch the previews and select </h3>
                            <img src={Video} alt="" />
                            <button className="btn" onClick={isAuthHandler}>
                              watch now
                            </button>
                          </div>
                        </li>
                      )}
                      {inBrazilRegion ? (
                        <li
                          className={`left ${activeTab === 4 ? "active" : ""}`}
                          ref={listRefs.current[3]}
                        >
                          <button className="tabsButton">
                            <b>4</b>
                            <img src={tabs4} alt="Tab 1" />
                          </button>
                          <span className="title">Confira</span>

                          <div className="tabScrollShow">
                            <h3> pague por onda ou selecione um plano</h3>
                            <img src={selectPlan} alt="" />
                            <h4>
                              Aceitamos a maioria dos cartões de crédito
                              <br></br> e cartões de débito
                            </h4>
                            <button
                              className="btn"
                              onClick={() => handleClick("price")}
                            >
                              ver Planos
                            </button>
                          </div>
                        </li>
                      ) : (
                        <li
                          className={`left ${activeTab === 4 ? "active" : ""}`}
                          ref={listRefs.current[3]}
                        >
                          <button className="tabsButton">
                            <b>4</b>
                            <img src={tabs4} alt="Tab 1" />
                          </button>
                          <span className="title">Check out</span>

                          <div className="tabScrollShow">
                            <h3>pay by wave or select a plan</h3>
                            <img src={selectPlan} alt="" />
                            <h4>
                              We accept the majority of credit <br></br> and
                              debit cards
                            </h4>
                            <button
                              className="btn"
                              onClick={() => handleClick("price")}
                            >
                              see plans
                            </button>
                          </div>
                        </li>
                      )}
                      {inBrazilRegion ? (
                        <li
                          className={` ${
                            activeTab === 5 ? "active" : ""
                          } lastTabs`}
                          ref={listRefs.current[4]}
                        >
                          <button className="tabsButton">
                            <b>5</b>
                            <img src={tabs5} alt="Tab 1" />
                          </button>
                          <span className="title">Download</span>

                          <div className="tabScrollShow">
                            <h3>
                              ANALISE SEU SURF E <br></br>
                              COMPARTILHE COM OS AMIGOS
                            </h3>
                            {/* <img src={VideoTabs} alt="" /> */}
                            <video muted loop autoPlay playsInline>
                              <source
                                src="https://www.surfeye.video/web/LANDING-PAGE-SECTION-4_v3_compressed.mp4"
                                type="video/mp4"
                              />
                            </video>
                            <button className="btn" onClick={isAuthHandler}>
                              Assista agora
                            </button>
                          </div>
                        </li>
                      ) : (
                        <li
                          className={` ${
                            activeTab === 5 ? "active" : ""
                          } lastTabs`}
                          ref={listRefs.current[4]}
                        >
                          <button className="tabsButton">
                            <b>5</b>
                            <img src={tabs5} alt="Tab 1" />
                          </button>
                          <span className="title">Download</span>

                          <div className="tabScrollShow">
                            <h3>
                              ANALYZE YOUR SURFING AND <br></br> SHARE WITH
                              FRIENDS
                            </h3>
                            {/* <img src={VideoTabs} alt="" /> */}
                            <video muted loop autoPlay playsInline>
                              <source
                                src="https://www.surfeye.video/web/LANDING-PAGE-SECTION-4_v3_compressed.mp4"
                                type="video/mp4"
                              />
                            </video>
                            <button className="btn" onClick={isAuthHandler}>
                              watch now
                            </button>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="tabsBottomButton">
                  <Link to="/?section=login">
                    <button className="btn" onClick={authHandler}>
                      {inBrazilRegion ? (
                        <b>COMECE AGORA</b>
                      ) : (
                        <b>Get Started</b>
                      )}
                    </button>
                  </Link>
                  {inBrazilRegion ? (
                    <>
                      <h5>você precisa de mais informações? </h5>
                      <div onClick={() => setIsFaqOpen(true)}>
                        <Link href="#">Clique aqui</Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <h5>do you need more info? </h5>
                      <div onClick={() => setIsFaqOpen(true)}>
                        <Link href="#">Click here</Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="joinBox">
            <div className="container">
              {inBrazilRegion ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <div className="boxDesign">
                      <div className="userList">
                        <div>
                          <span>junte-se a nós</span>
                          <div className="userImg1">
                            <img src={userList1} alt="" />
                            <img src={userList2} alt="" />
                            <img src={userList3} alt="" />
                          </div>
                          <div className="userPlus">
                            <h3>8.000+</h3>
                            <h5>usuários</h5>
                          </div>
                        </div>
                        <div
                          className="userImg"
                          style={{ transform: "translate(40px, 0px)" }}
                        >
                          <img src={userList1} alt="" />
                          <img src={userList2} alt="" />
                          <img src={userList3} alt="" />
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="boxDesign">
                      <div className="userList">
                        <div>
                          <span>e mais de</span>
                          <div className="userImg1 imgSize">
                            <img src={WAVEmix} alt="" />
                          </div>
                          <div className="userPlus">
                            <h3>5.2m+</h3>
                            <h5>ondas registradas</h5>
                          </div>
                        </div>
                        <div className="userImg imgSize">
                          <img src={WAVEmix} alt="" />
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <div className="boxDesign">
                      <div className="userList">
                        <div>
                          <span>join our </span>
                          <div className="userImg1">
                            <img src={userList1} alt="" />
                            <img src={userList2} alt="" />
                            <img src={userList3} alt="" />
                          </div>
                          <div className="userPlus">
                            <h3>8.000+</h3>
                            <h5>users</h5>
                          </div>
                        </div>
                        <div
                          className="userImg"
                          style={{ transform: "translate(40px, 0px)" }}
                        >
                          <img src={userList1} alt="" />
                          <img src={userList2} alt="" />
                          <img src={userList3} alt="" />
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="boxDesign">
                      <div className="userList">
                        <div>
                          <span>and more than</span>
                          <div className="userImg1 imgSize">
                            <img src={WAVEmix} alt="" />
                          </div>
                          <div className="userPlus">
                            <h3>5.2m+</h3>
                            <h5>waves recorded</h5>
                          </div>
                        </div>
                        <div className="userImg imgSize">
                          <img src={WAVEmix} alt="" />
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>

          <div
            className="videosAnywhare"
            ref={(prev) => {
              headerRef.current["highlightmobile"] = prev;
            }}
          >
            <div className="container">
              <Grid container>
                <Grid item xs={12} md={9} style={{ margin: "0 auto" }}>
                  <div className="titleHeading">
                    {inBrazilRegion ? (
                      <h3>
                        assista e compartilhe seu <br></br> vídeos em qualquer
                        lugar
                      </h3>
                    ) : (
                      <h3>
                        watch and share your <br></br> videos anywhere
                      </h3>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="videoBox">
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        {inBrazilRegion ? (
                          <div className="subHeading">
                            <h5>seja gravado</h5>
                            <p>a qualquer dia. a qualquer hora.</p>
                          </div>
                        ) : (
                          <div className="subHeading">
                            <h5>get recorded</h5>
                            <p>Any day. at any time.</p>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12} lg={10}>
                        <div className="videoModal">
                          <div className="imgweb">
                            <div className="laptopVideoMargeWeb">
                              <img className="webimg" src={webimg} alt="" />
                              <video muted loop autoPlay playsInline>
                                <source
                                  src="https://www.surfeye.video/web/LANDING-PAGE-SECTION-4_v3_compressed.mp4"
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                            <div className="laptopVideoMargeMobile">
                              {/* <img className="mobileimg" src={mobileimg} alt="" /> */}
                              <video muted loop autoPlay playsInline>
                                <source
                                  src="https://www.surfeye.video/web/SE_LP_new_mobile_site_v.2.mp4"
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          </div>
                          {inBrazilRegion ? (
                            <ul>
                              <li>
                                <span>
                                  <img src={book} alt="" />
                                </span>
                                <span>
                                  sempre ligado. <br></br> não precisa reservar
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img src={buyLater} alt="" />
                                </span>
                                <span>
                                  surfe primeiro <br></br> compre depois
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img src={download} alt="" />
                                </span>
                                <span>
                                  download <br></br> onda por onda
                                </span>
                              </li>
                            </ul>
                          ) : (
                            <ul>
                              <li>
                                <span>
                                  <img src={book} alt="" />
                                </span>
                                <span>
                                  always on. <br></br> no need to book
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img src={buyLater} alt="" />
                                </span>
                                <span>
                                  surf first <br></br> buy later
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img src={download} alt="" />
                                </span>
                                <span>
                                  download <br></br> wave by wave
                                </span>
                              </li>
                            </ul>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>

      <div
        className="locationSection"
        ref={(prev) => {
          headerRef.current["location"] = prev;
        }}
      >
        <div className="container">
          <Tooltip
            content={tipInfo.message}
            position={tipInfo.position}
            isOpen={tipInfo.opened}
            positionStrategy={"fixed"}
            style={{
              backgroundColor: "white",
              color: "black",
              zIndex: 10000,
            }}
          />
          <Grid container>
            {inBrazilRegion ? (
              <Grid item xs={12}>
                <div className="locationContent">
                  <h3>localizações</h3>
                  <div style={{ overflow: "hidden", borderRadius: "50px" }}>
                    <div className="TapsButton">
                      <button
                        className={`btn ${Location === "region" ? "active" : ""}`}
                        onClick={() => setLocation("region")}
                      >
                        região
                      </button>
                      <button
                        className={`btn ${Location === "spot" ? "active" : ""}`}
                        onClick={() => {
                          setLocation("spot");
                          if (regionitem === 0) {
                            handleRegionItemClick(region.id, region);
                            // setRegionItem(0);
                            // setRegion(activeRegion[0]);
                            // //
                            // setActiveSpot(spots);
                          }
                        }}
                      >
                        local
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <div className="locationContent">
                  <h3>Locations</h3>
                  <div style={{ overflow: "hidden", borderRadius: "50px" }}>
                    <div className="TapsButton">
                      <button
                        className={`btn ${Location === "region" ? "active" : ""}`}
                        onClick={() => setLocation("region")}
                      >
                        region
                      </button>
                      <button
                        className={`btn ${Location === "spot" ? "active" : ""}`}
                        onClick={() => {
                          setLocation("spot");
                          if (regionitem === 0) {
                            handleRegionItemClick(region.id, region);
                            // setRegionItem(0);
                            // setRegion(activeRegion[0]);
                            // //
                            // setActiveSpot(spots);
                          }
                        }}
                      >
                        Spot
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
            <Grid item xs={12} md={10} style={{ margin: "0 auto" }}>
              {Location == "region" ? (
                <div className="mapLocation">
                  <div className="mapViewImg">
                    <div
                      className="map"
                      width="100%"
                      height="42%"
                      style={{ border: "none" }}
                      onMouseMove={checkTooltip}
                    >
                      <APIProvider apiKey={GOOGLE_MAP_KEY}>
                        <Map
                          center={regionCenter}
                          zoom={mapZoom.region}
                          styles={MAP_STYLES}
                          mapTypeControl={false}
                          minZoom={0}
                          maxZoom={20}
                          panControl={true}
                          panControlOptions={{
                            position:
                              window?.google?.maps?.ControlPosition
                                ?.LEFT_CENTER ?? 0,
                          }}
                          keyboardShortcuts={true}
                          fullscreenControl={false}
                          rotateControl={false}
                          controlled={false}
                          gestureHandling="cooperative"
                          zoomControl={false}
                          mapTypeControlOptions={{
                            mapTypeIds: ["roadmap", "satellite", "terrain"],
                          }}
                          mapTypeId="roadmap"
                          onZoomChanged={(e) => updateMap(e, "region", "zoom")}
                          onMouseout={hideTooltip}
                          onDragstart={(e) => updateMap(e, "region", "drag")}
                        >
                          {activeRegion.map((region) =>
                            region.spots.map((spot, index) => (
                              <Marker
                                key={spot.slug}
                                position={spot.coord}
                                icon={MarkIcon}
                                onClick={() => onSpotClick(region, spot)}
                                onMouseOver={showToolTip(spot)}
                                onMouseout={hideTooltip}
                                animation={
                                  tipInfo.message === spot.name
                                    ? (window?.google?.maps?.Animation?.MJ ??
                                      null)
                                    : window?.google?.maps?.Animation?.BOUNCE
                                }
                              />
                            )),
                          )}
                        </Map>
                      </APIProvider>
                    </div>
                  </div>
                  <div className="mapBox">
                    <OwlCarousel className="owl-theme" {...options}>
                      {activeRegion &&
                        activeRegion.map((item, id) => (
                          <div className="item" key={item.id}>
                            <div
                              className={`box ${
                                item.active ? "active" : "disabled"
                              }`}
                              onClick={() =>
                                item.active && handleRegionItemClick(id, item)
                              }
                            >
                              <div className="boxCenter">
                                <h3>{item.name}</h3>
                                <p>{item.spot}</p>
                                {inBrazilRegion ? (
                                  <span>ver mais</span>
                                ) : (
                                  <span>see more</span>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </OwlCarousel>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {Location == "spot" ? (
                <div className="mapLocation">
                  <div className="mapViewImg">
                    <div
                      className="map"
                      width="100%"
                      height="42%"
                      style={{ border: "none" }}
                      onMouseMove={checkTooltip}
                    >
                      <APIProvider apiKey={GOOGLE_MAP_KEY}>
                        <Map
                          center={spotCenter}
                          zoom={mapZoom.spot}
                          styles={MAP_STYLES}
                          mapTypeControl={false}
                          minZoom={0}
                          maxZoom={20}
                          keyboardShortcuts={false}
                          fullscreenControl={false}
                          rotateControl={false}
                          zoomControl={false}
                          mapTypeControlOptions={{
                            mapTypeIds: ["roadmap", "satellite", "terrain"],
                          }}
                          mapTypeId="roadmap"
                          onZoomChanged={(e) => updateMap(e, "spot", "zoom")}
                          onMouseout={hideTooltip}
                          onBoundsChanged={(e) =>
                            updateMap(e, "spot", "bounds")
                          }
                        >
                          {activeSpot.map((spot, index) => (
                            <Marker
                              key={index}
                              position={spot.marker}
                              icon={MarkIcon}
                              onClick={() => onSpotClick(region, spot)}
                              onMouseOver={showToolTip(spot)}
                              onMouseout={hideTooltip}
                              animation={
                                tipInfo.message === spot.name
                                  ? (window?.google?.maps?.Animation?.MJ ??
                                    null)
                                  : window?.google?.maps?.Animation?.BOUNCE
                              }
                            />
                          ))}
                        </Map>
                      </APIProvider>
                    </div>
                    <div className="mapContent">
                      <h5>{region.name}</h5>
                    </div>
                  </div>
                  <div className="mapBox">
                    <OwlCarousel className="owl-theme owl-refresh" {...options}>
                      {activeSpot &&
                        activeSpot.map((spot, id) => (
                          <div className="item" key={spot.id}>
                            <div
                              className={`box ${spot.active ? "active" : ""}`}
                              onClick={() => handleItemClick(id)}
                            >
                              {spot.cmp}
                            </div>
                          </div>
                        ))}
                    </OwlCarousel>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </div>
      </div>

      {inBrazilRegion ? (
        <div className="startWatching">
          <div className="container">
            <Grid container>
              <Grid item xs={12} sm={10} style={{ margin: "0 auto" }}>
                <div className="wavePoolsContent">
                  <h2>assista já</h2>
                  <h4>
                    Entre ou cadastre-se para assistir aos seus vídeos depois de
                    surfar.
                    <br></br>{" "}
                    <span>
                      {" "}
                      Não precisa agendar, seja gravado automaticamente.
                    </span>
                  </h4>
                  <Link to="/?section=login">
                    <button onClick={authHandler} className="btn">
                      <b>COMECE AGORA</b>
                    </button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div className="startWatching">
          <div className="container">
            <Grid container>
              <Grid item xs={12} sm={10} style={{ margin: "0 auto" }}>
                <div className="wavePoolsContent">
                  <h2>START WATCHING</h2>
                  <h4>
                    Login or register to watch your videos after surfing.{" "}
                    <br></br>{" "}
                    <span> No need to book, get automatically recorded.</span>
                  </h4>
                  <Link to="/?section=login">
                    <button onClick={authHandler} className="btn">
                      {inBrazilRegion ? (
                        <b>COMECE AGORA</b>
                      ) : (
                        <b>Get Started</b>
                      )}
                    </button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}

      <div
        className="pricingSection"
        ref={(prev) => {
          headerRef.current["price"] = prev;
        }}
      >
        <div className="container">
          <Grid container>
            <Grid item xs={12}>
              <div className="content">
                {inBrazilRegion ? <h2>Preços</h2> : <h2>Pricing</h2>}
              </div>
            </Grid>
          </Grid>
          <div className="mobileSmallBox">
            <Grid container>
              <Grid item xs={12} lg={6} md={7}>
                {inBrazilRegion ? (
                  <div className="paddingLeft">
                    <div
                      className={`boxPricingTop one ${
                        pricing === "unlimited" ? "active" : ""
                      }`}
                      id="top1"
                      onClick={() => setPricing("unlimited")}
                      style={
                        pricing === "unlimited"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="title">
                        <h3>
                          Surf eye <span>unlimited</span>
                        </h3>
                        <IoArrowForwardSharp />
                      </div>
                      <p>
                        <span>30 dias de vídeos ilimitados</span>
                        <span>Acesso a todas as nossas câmeras</span>
                        <span>Sem anúncios</span>
                        <span>Prévias completas</span>
                      </p>

                      <div className="priceRate">
                        {inBrazilRegion ? (
                          <h4>
                            R$49 <span>/mês</span>
                          </h4>
                        ) : (
                          <h4>
                            €9,99 <span>/month</span>
                          </h4>
                        )}
                      </div>
                    </div>
                    {!inBrazilRegion ? (
                      <div
                        className={`boxPricingTop two ${
                          pricing === "wave" ? "active" : ""
                        }`}
                        id="top2"
                        onClick={() => setPricing("wave")}
                        style={
                          pricing === "wave"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <div className="title">
                          <h3>ONDAS</h3>
                          <IoArrowForwardSharp />
                        </div>
                        <p>
                          <span>Um vídeo de uma onda surfada</span>
                          <span>Você pode selecionar quantos quiser</span>
                        </p>

                        <div className="priceRate">
                          <h4>
                            €4,99 <span>/aceno</span>
                          </h4>
                        </div>
                      </div>
                    ) : null}
                    <div
                      className={`boxPricingTop three ${
                        pricing === "day" ? "active" : ""
                      }`}
                      id="top3"
                      onClick={() => setPricing("day")}
                      style={
                        pricing === "day"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="title">
                        <h3>PASSE DIÁRIO</h3>
                        <IoArrowForwardSharp />
                      </div>
                      <p>
                        <span>Baixar Ondas Ilimitadas</span>
                        <span>Durante um dia </span>
                        <span>Em apenas uma localização</span>
                      </p>

                      <div className="priceRate">
                        {inBrazilRegion ? (
                          <h4>
                            R$29 <span>/dia</span>
                          </h4>
                        ) : (
                          <h4>
                            €7,99 <span>/day</span>
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="paddingLeft">
                    <div
                      className={`boxPricingTop one ${
                        pricing === "unlimited" ? "active" : ""
                      }`}
                      id="top1"
                      onClick={() => setPricing("unlimited")}
                      style={
                        pricing === "unlimited"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="title">
                        <h3>
                          Surf eye <span>unlimited</span>
                        </h3>
                        <IoArrowForwardSharp />
                      </div>
                      <p>
                        <span>30 Days of Unlimited Videos</span>
                        <span>Access to All of Our Cameras</span>
                        <span>Ad-free</span>
                        <span>Full-Length Previews</span>
                      </p>

                      <div className="priceRate">
                        {inBrazilRegion ? (
                          <h4>
                            R$49 <span>/mês</span>
                          </h4>
                        ) : (
                          <h4>
                            €9,99 <span>/month</span>
                          </h4>
                        )}
                      </div>
                    </div>
                    <div
                      className={`boxPricingTop two ${
                        pricing === "wave" ? "active" : ""
                      }`}
                      id="top2"
                      onClick={() => setPricing("wave")}
                      style={
                        pricing === "wave"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="title">
                        <h3>WAVES</h3>
                        <IoArrowForwardSharp />
                      </div>
                      <p>
                        <span>One Video of a Surfed Wave</span>
                        <span>You can Select as Many as You Want</span>
                      </p>

                      <div className="priceRate">
                        <h4>
                          €4,99 <span>/wave</span>
                        </h4>
                      </div>
                    </div>
                    <div
                      className={`boxPricingTop three ${
                        pricing === "day" ? "active" : ""
                      }`}
                      id="top3"
                      onClick={() => setPricing("day")}
                      style={
                        pricing === "day"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="title">
                        <h3>DAY PASS</h3>
                        <IoArrowForwardSharp />
                      </div>
                      <p>
                        <span>Download Unlimited Waves</span>
                        <span>For One Selected Day </span>
                        <span>For One Location Only</span>
                      </p>

                      <div className="priceRate">
                        {inBrazilRegion ? (
                          <h4>
                            R$29 <span>/dia</span>
                          </h4>
                        ) : (
                          <h4>
                            €7,99 <span>/day</span>
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
            <div className="smallBox">
              {inBrazilRegion ? (
                <div onClick={() => setIsFaqOpen(true)}>
                  <h6>
                    VOCÊ PRECISA DE MAIS INFORMAÇÕES? <MdArrowForwardIos />{" "}
                    <Link href="#" style={{ display: "none" }}>
                      Clique aqui
                    </Link>
                  </h6>
                </div>
              ) : (
                <div onClick={() => setIsFaqOpen(true)}>
                  <h6>
                    DO YOU NEED MORE INFO? <MdArrowForwardIos />{" "}
                    <Link href="#" style={{ display: "none" }}>
                      Click here
                    </Link>
                  </h6>
                </div>
              )}

              <Grid container spacing={2} style={{ alignItems: "center" }}>
                {inBrazilRegion ? (
                  <Grid item xs={12} md={4} lg={5}>
                    <div className="list">
                      <div
                        style={
                          pricing === "unlimited"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <ul>
                          <li>
                            <span>
                              <img src={priceIcon1} alt="" />
                            </span>
                            <span>
                              Funcionalidades <br></br> premium
                            </span>
                          </li>
                          <li>
                            <span>
                              <img src={priceIcon2} alt="" />
                            </span>
                            <span>
                              Cancele <br></br> quando quiser
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div
                        style={
                          pricing === "wave"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <ul>
                          <li>
                            <span>
                              <img src={priceIcon3} alt="" />
                            </span>
                            <span>
                              Pague como <br></br> você vai
                            </span>
                          </li>
                          <li>
                            <span>
                              <img src={priceIcon4} alt="" />
                            </span>
                            <span>
                              Decidir <br></br> depois do surf
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div
                        style={
                          pricing === "day"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <ul>
                          <li>
                            <span>
                              <img src={priceIcon3} alt="" />
                            </span>
                            <span>
                              Pague como <br></br> você vai
                            </span>
                          </li>
                          <li>
                            <span>
                              <img src={priceIcon4} alt="" />
                            </span>
                            <span>
                              Decidir <br></br> depois do surf
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={4} lg={5}>
                    <div className="list">
                      <div
                        style={
                          pricing === "unlimited"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <ul>
                          <li>
                            <span>
                              <img src={priceIcon1} alt="" />
                            </span>
                            <span>
                              premium <br></br> features
                            </span>
                          </li>
                          <li>
                            <span>
                              <img src={priceIcon2} alt="" />
                            </span>
                            <span>
                              Cancel <br></br> Anytime
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div
                        style={
                          pricing === "wave"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <ul>
                          <li>
                            <span>
                              <img src={priceIcon3} alt="" />
                            </span>
                            <span>
                              Pay as <br></br> you go
                            </span>
                          </li>
                          <li>
                            <span>
                              <img src={priceIcon4} alt="" />
                            </span>
                            <span>
                              Decide <br></br> after surf
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div
                        style={
                          pricing === "day"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <ul>
                          <li>
                            <span>
                              <img src={priceIcon3} alt="" />
                            </span>
                            <span>
                              Pay as <br></br> you go
                            </span>
                          </li>
                          <li>
                            <span>
                              <img src={priceIcon4} alt="" />
                            </span>
                            <span>
                              Decide <br></br> after surf
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Grid>
                )}

                {inBrazilRegion ? (
                  <Grid item xs={12} md={8} lg={7}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        style={
                          pricing === "unlimited"
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        id="top1"
                        onClick={() => setPricing("unlimited")}
                      >
                        <div
                          className="box"
                          id="top1"
                          style={{ border: "4px solid #FABA06" }}
                        >
                          <div className="title">
                            <h3>
                              Surf eye <span>unlimited</span>
                            </h3>
                            <IoArrowForwardSharp />
                          </div>
                          <p>
                            <span>30 dias de vídeos ilimitados</span>
                            <span>Acesso a todas as nossas câmeras</span>
                            <span>Sem anúncios</span>
                            <span>Prévias completas</span>
                          </p>

                          <div className="priceRate">
                            {inBrazilRegion ? (
                              <h4>
                                R$49 <span>/mês</span>
                              </h4>
                            ) : (
                              <h4>
                                €9,99 <span>/month</span>
                              </h4>
                            )}
                          </div>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        id="top3"
                        style={
                          pricing === "day"
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        onClick={() => setPricing("day")}
                      >
                        <div
                          className="box"
                          id="top1"
                          style={{
                            backgroundColor: "#2B485C",
                            border: "4px solid #2B485C",
                          }}
                        >
                          <div className="title">
                            <h3>PASSE DIÁRIO</h3>
                            <IoArrowForwardSharp />
                          </div>
                          <p>
                            <span>Baixar Ondas Ilimitadas</span>
                            <span>Durante um dia </span>
                            <span>Em apenas uma localização</span>
                          </p>

                          <div className="priceRate">
                            {inBrazilRegion ? (
                              <h4>
                                R$29 <span>/dia</span>
                              </h4>
                            ) : (
                              <h4>
                                €7,99 <span>/day</span>
                              </h4>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={8} lg={7}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        style={
                          pricing === "unlimited"
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        id="top1"
                        onClick={() => setPricing("unlimited")}
                      >
                        <div
                          className="box"
                          id="top1"
                          style={{ border: "4px solid #FABA06" }}
                        >
                          <div className="title">
                            <h3>
                              Surf eye <span>unlimited</span>
                            </h3>
                            <IoArrowForwardSharp />
                          </div>
                          <p>
                            <span>30 Days of Unlimited Videos</span>
                            <span>Access to All of Our Cameras</span>
                            <span>Ad-free</span>
                            <span>Full-Length Previews</span>
                          </p>

                          <div className="priceRate">
                            {inBrazilRegion ? (
                              <h4>
                                R$49 <span>/mês</span>
                              </h4>
                            ) : (
                              <h4>
                                €9,99 <span>/month</span>
                              </h4>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        id="top2"
                        style={
                          pricing === "wave"
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        onClick={() => setPricing("wave")}
                      >
                        <div
                          className="box"
                          id="top1"
                          style={{
                            backgroundColor: "#86A9C5",
                            border: "4px solid #86A9C5",
                          }}
                        >
                          <div className="title">
                            <h3>WAVES</h3>
                            <IoArrowForwardSharp />
                          </div>
                          <p>
                            <span>One Video of a Surfed Wave</span>
                            <span>You can Select as Many as You Want</span>
                          </p>

                          <div className="priceRate">
                            <h4>
                              €4,99 <span>/wave</span>
                            </h4>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        id="top3"
                        style={
                          pricing === "day"
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        onClick={() => setPricing("day")}
                      >
                        <div
                          className="box"
                          id="top1"
                          style={{
                            backgroundColor: "#2B485C",
                            border: "4px solid #2B485C",
                          }}
                        >
                          <div className="title">
                            <h3>DAY PASS</h3>
                            <IoArrowForwardSharp />
                          </div>
                          <p>
                            <span>Download Unlimited Waves</span>
                            <span>For One Selected Day </span>
                            <span>For One Location Only</span>
                          </p>

                          <div className="priceRate">
                            {inBrazilRegion ? (
                              <h4>
                                R$29 <span>/dia</span>
                              </h4>
                            ) : (
                              <h4>
                                €7,99 <span>/day</span>
                              </h4>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </div>
      </div>

      {inBrazilRegion ? (
        <div className="giftCards">
          <div className="container">
            <Grid
              container
              spacing={{ xs: 0, sm: 0, md: 3, lg: 3 }}
              style={{ margin: "0 auto" }}
            >
              <Grid item xs={12} lg={5} sm={5}>
                <div className="headingGift">
                  <h3>Cartão presente</h3>
                  <h5>Faça um surfista feliz</h5>
                  <button
                    onClick={() =>
                      (window.location.href =
                        "https://buy.stripe.com/dR628o9jC1llbhm7sJ")
                    }
                    className="btn"
                  >
                    compre agora
                  </button>
                </div>
              </Grid>
              <Grid item xs={12} lg={6} sm={6}>
                <div className="monthBox">
                  <div className="priceBox">
                    <div className="content">
                      <h2>
                        1 Mês <img src={giftArrow} alt="" />
                      </h2>
                      <h3>
                        SURF EYE <span> UNLIMITED</span>
                      </h3>
                      <p>
                        Ofereça 1 Mês de Filmagens <br></br> Ilimitadas
                      </p>
                    </div>
                    <h5 className="price">R$49</h5>
                  </div>
                  <h6>Válido por 1 ano e todas as câmeras</h6>

                  <button
                    className="btn"
                    onClick={() =>
                      (window.location.href = inBrazilRegion
                        ? "https://buy.stripe.com/aEU7ve0Bt1gDdH23cg"
                        : "https://buy.stripe.com/dR628o9jC1llbhm7sJ")
                    }
                  >
                    pegue agora
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div className="giftCards">
          <div className="container">
            <Grid
              container
              spacing={{ xs: 0, sm: 0, md: 3, lg: 3 }}
              style={{ margin: "0 auto" }}
            >
              <Grid item xs={12} lg={5} sm={5}>
                <div className="headingGift">
                  <h3>Gift card</h3>
                  <h5>MAke a surfer happy</h5>
                  <button
                    onClick={() =>
                      (window.location.href =
                        "https://buy.stripe.com/dR628o9jC1llbhm7sJ")
                    }
                    className="btn"
                  >
                    get now
                  </button>
                </div>
              </Grid>
              <Grid item xs={12} lg={6} sm={6}>
                <div className="monthBox">
                  <div className="priceBox">
                    <div className="content">
                      <h2>
                        1 Month <img src={giftArrow} alt="" />
                      </h2>
                      <h3>
                        SURF EYE <span> UNLIMITED</span>
                      </h3>
                      <p>
                        Gift 1 Month of Unlimited Video <br></br> Footage
                      </p>
                    </div>
                    <h5 className="price">€9.99</h5>
                  </div>
                  <h6>Valid for 1 year and All Cameras</h6>

                  <button
                    className="btn"
                    onClick={() =>
                      (window.location.href =
                        "https://buy.stripe.com/dR628o9jC1llbhm7sJ")
                    }
                  >
                    get now
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}

      {inBrazilRegion ? (
        <div className="wavePools">
          <div className="container">
            <Grid container>
              <Grid item xs={12} md={12} style={{ margin: "0 auto" }}>
                <div className="wavePoolsContent">
                  <h2>piscinas de ondas</h2>
                  <h4>TECNOLOGIA OCEÂNICA. IMPULSIONANDO piscinas de ondas.</h4>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div className="wavePools">
          <div className="container">
            <Grid container>
              <Grid item xs={12} md={12} style={{ margin: "0 auto" }}>
                <div className="wavePoolsContent">
                  <h2>wave pools</h2>
                  <h4>Ocean-born technology. Supercharging wave pools.</h4>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}

      <div className="poolsBox">
        <div className="container">
          <Grid
            container
            spacing={5}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={4} lg={2} md={3}>
              <div className="boxPools">
                <a href="https://wavepools.surfeye.video/">
                  <img src={poolsRif010} alt="All Our Pools" />
                </a>
              </div>
            </Grid>
            <Grid item xs={4} lg={2} md={3}>
              <div className="boxPools">
                <a href="https://rif010.surfeye.video/">
                  <img src={poolsRif010} alt="Rif010 Rotterdam" />
                </a>
              </div>
            </Grid>
            <Grid item xs={4} lg={2} md={3}>
              <div className="boxPools">
                <a href="https://skudinsurfad.surfeye.video/">
                  <img src={poolsSkudin} alt="Skudin Surf AD NYC" />
                </a>
              </div>
            </Grid>
            <Grid item xs={4} lg={2} md={3}>
              <div className="boxPools">
                <a href="https://wellenwerk.surfeye.video/">
                  <img src={poolsWellenwerk} alt="Wellenwerk Berlin" />
                </a>
              </div>
            </Grid>
            <Grid item xs={4} lg={2} md={3}>
              <div className="boxPools">
                <a href="https://wakeparadise.surfeye.video/">
                  <img src={poolsWakeparadise} alt="Wake Paradise Milan" />
                </a>
              </div>
            </Grid>
            {/* <Grid item xs={4} lg={2} md={3}>
              <div className="boxPools">
                <a href="https://www.citywave.lt/">
                  <img src={poolsCitywaveLt} alt="Citywave Vilnius" />
                </a>
              </div>
            </Grid> */}
            <Grid item xs={4} lg={2} md={3}>
              <div className="boxPools">
                <a href="https://surfcenter.net/">
                  <img src={poolsSurfCentre} alt="Surf Center Curitiba" />
                </a>
              </div>
            </Grid>
            <Grid item xs={4} lg={2} md={3}>
              {inBrazilRegion ? (
                <div className="boxPools">
                  <h3>MAIS EM BREVE...</h3>
                </div>
              ) : (
                <div className="boxPools">
                  <h3>MORE SOON...</h3>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>

      {inBrazilRegion ? (
        <div className="cameraSection">
          <div className="container">
            <div className="backgroundColor">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <div className="content">
                    <h3>Quer hospedar uma câmera?</h3>
                    <h4>
                      Junte-se à nossa rede e instale uma câmera Surf Eye em seu
                      estabelecimento.
                    </h4>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={camera1} alt="" />
                    </div>
                    <h5>
                      aumente <br />
                      visitas
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={camera2} alt="" />
                    </div>
                    <h5>
                      Aumente a visibilidade na
                      <br /> comunidade de surf
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={camera3} alt="" />
                    </div>
                    <h5>
                      Melhore <br />
                      sua oferta
                    </h5>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={{ xs: 3, sm: 3, md: 5, lg: 10 }}>
                <Grid item xs={6} md={6}>
                  <div className="boxContact">
                    <h3>Piscina de ondas</h3>
                    <a href="https://wavepools.surfeye.video" className="btn">
                      Saiba mais
                    </a>
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div className="boxContact">
                    <h3>Ponto de praia</h3>
                    <a href="mailto:comercial@surfeye.video" className="btn">
                      CONTATE-NOS
                    </a>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <div className="cameraSection">
          <div className="container">
            <div className="backgroundColor">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <div className="content">
                    <h3>Want to host a Camera?</h3>
                    <h4>
                      Join our network and install a Surf Eye camera in your
                      venue
                    </h4>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={camera1} alt="" />
                    </div>
                    <h5>
                      increase <br />
                      visits
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={camera2} alt="" />
                    </div>
                    <h5>
                      grow visibility in the
                      <br /> surfing community
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={camera3} alt="" />
                    </div>
                    <h5>
                      enhance <br />
                      your offer
                    </h5>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={{ xs: 3, sm: 3, md: 5, lg: 10 }}>
                <Grid item xs={6} md={6}>
                  <div className="boxContact">
                    <h3>Wave pool</h3>
                    <a href="mailto: info@surfeye.video" className="btn">
                      CONTACT US
                    </a>
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div className="boxContact">
                    <h3>Beach spot</h3>
                    <a href="mailto: info@surfeye.video" className="btn">
                      CONTACT US
                    </a>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      )}
      {inBrazilRegion ? (
        <div style={{ backgroundColor: "#F8F8F8" }}>
          <div className="whyVideo">
            <div className="container">
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <div className="content">
                    <h3>porquê vídeos?</h3>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={whyvideo1} alt="" />
                    </div>
                    <h5>
                      CRIE MEMÓRIAS
                      <br />
                      PARA A VIDA
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={whyvideo2} alt="" />
                    </div>
                    <h5>
                      ASSISTA O SEU SURF
                      <br />E EVOLUA
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={whyvideo3} alt="" />
                    </div>
                    <h5>
                      COMPARTILHE <br />
                      COM AMIGOS
                    </h5>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <div className="contentBottom">
                    <h4>TREINE COMO UM PRO COM SURF EYE</h4>
                    <Link to="/?section=login">
                      <button onClick={authHandler} className="btn">
                        <b>COMECE AGORA</b>
                      </button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ backgroundColor: "#F8F8F8" }}>
          <div className="whyVideo">
            <div className="container">
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <div className="content">
                    <h3>Why videos?</h3>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={whyvideo1} alt="" />
                    </div>
                    <h5>
                      CREATE LIFETIME <br />
                      MEMORIES
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={whyvideo2} alt="" />
                    </div>
                    <h5>
                      WATCH YOURSELF AND <br />
                      IMPROVE
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  <div className="contentBox">
                    <div className="imgHeight">
                      <img src={whyvideo3} alt="" />
                    </div>
                    <h5>
                      SHARE WITH <br />
                      FRIENDS
                    </h5>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <div className="contentBottom">
                    <h4>TRAIN LIKE A PRO WITH SURF EYE</h4>
                    <Link to="/?section=login">
                      <button onClick={authHandler} className="btn">
                        {inBrazilRegion ? (
                          <b>COMECE AGORA</b>
                        ) : (
                          <b>Get Started</b>
                        )}
                      </button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      )}
      <Footer />

      {/* FAQ modal */}
      {isFaqOpen ? (
        <div className="faqModal">
          <div className="background"></div>
          <div className="faqStart">
            <div className="faqHeading">
              {inBrazilRegion ? <h3>Perguntas frequentes</h3> : <h3>FAQS</h3>}
              <div className="closeModal" onClick={() => setIsFaqOpen(false)}>
                <MdCancel />
              </div>
            </div>

            <div className="faqContent">
              {inBrazilRegion ? (
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <div className="topContent">
                        <img src={faq1} alt="Tab 1" />
                        <h2>em geral</h2>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="faqunderContent">
                        <h3>O QUE É SURF EYE?</h3>
                        <p>
                          Surf eye é uma rede de câmeras de alta resolução que
                          gravam e editam automaticamente cada onda surfada
                          dentro de nossas Áreas de Gravação. Veja os locais{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setLocation("region");
                              handleClick("location");
                              setIsFaqOpen(false);
                            }}
                          >
                            {" "}
                            <b>Aqui</b>
                          </span>
                        </p>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <div className="topContent">
                        <img src={faq1} alt="Tab 1" />
                        <h2>general</h2>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="faqunderContent">
                        <h3>WHAT IS SURF EYE?</h3>
                        <p>
                          Surf eye is a network of high-resolution cameras that
                          automatically record and edit every wave surfed within
                          our Recording Areas. See locations{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setLocation("region");
                              handleClick("location");
                              setIsFaqOpen(false);
                            }}
                          >
                            {" "}
                            <b>Here</b>
                          </span>
                        </p>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}

              {inBrazilRegion ? (
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>
                      <div className="topContent">
                        <img src={faq2} alt="Tab 2" />
                        <h2>seleção de ondas</h2>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="faqunderContent">
                        <h3>Preciso reservar com antecedência?</h3>
                        <p>
                          Não, nossas câmeras gravam todos os surfistas dentro
                          da Área de Gravação, tenham eles pago ou não.
                        </p>

                        <h3>Como faço para ser gravado?</h3>
                        <p>
                          Surfe em uma de nossas Áreas de Gravação. Nossas
                          câmeras estão ativas todos os dias; você só precisa
                          lembrar do horário em que sua sessão começou. Visite
                          nosso site 15 minutos após sua sessão para selecionar
                          suas ondas de nossas prévias.
                        </p>

                        <h3>Como a câmera sabe quem eu sou?</h3>
                        <p>
                          Nossas câmeras não identificam indivíduos. Após sua
                          sessão, visite nosso site, inscreva-se ou faça login,
                          e selecione e baixe suas ondas.
                        </p>

                        <h3>Preciso usar alguma coisa para ser gravado?</h3>
                        <p>
                          Não, não é necessário, mas se você quiser se tornar
                          mais reconhecível, você pode usar itens ou coloridos
                          distintos (lycra, chapéu, etc.), embora seja
                          totalmente opcional.
                        </p>

                        <h3>
                          Só consigo ver alguns segundos da minha onda; como sei
                          se a onda foi totalmente filmada?
                        </h3>
                        <p>
                          Nossas prévias têm todas 4 segundos de duração.
                          Enquanto você surfar dentro da área de gravação, o
                          vídeo completo da sua onda estará disponível para
                          download.
                        </p>

                        <h3>
                          A qualidade do vídeo final é melhor do que a prévia?
                        </h3>
                        <p>
                          Yes, the quality of the downloaded video is higher
                          than the preview.
                        </p>

                        <h3>
                          Preciso selecionar todas as ondas que desejo baixar
                          antes de finalizar a compra?
                        </h3>
                        <p>
                          Sim, selecione todas as suas ondas antes de finalizar
                          a compra; você não pode adicionar mais depois.
                        </p>

                        <h3>
                          Eu naveguei alguns dias atrás e ainda não baixei meus
                          vídeos. Ainda posso comprá-los?
                        </h3>
                        <p>
                          Sim, mas se apresse. Todos os vídeos não reivindicados
                          são excluídos 7 dias após a gravação.
                        </p>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>
                      <div className="topContent">
                        <img src={faq2} alt="Tab 2" />
                        <h2>wave selection</h2>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="faqunderContent">
                        <h3>Do I need to book in advance?</h3>
                        <p>
                          No, our cameras record every surfer inside the
                          Recording Area, whether they’ve paid or not.
                        </p>

                        <h3>How do i get recorded?</h3>
                        <p>
                          Surf within one of our Recording Areas. Our cameras
                          are active every day; you just need to remember the
                          time your session started. Visit our website 15
                          minutes after your session to select your waves from
                          our previews.
                        </p>

                        <h3>How does the camera know who I am?</h3>
                        <p>
                          Our cameras don’t identify individuals. After your
                          session, visit our website, sign up or log in, and
                          select and download your waves.
                        </p>

                        <h3>Do i need to wear something to be recorded?</h3>
                        <p>
                          No, it’s not necessary, but if you want to make
                          yourself more recognizable, you can wear distinctive
                          or colored items (lycra, hat, etc.), though it’s
                          entirely optional.
                        </p>

                        <h3>
                          I can only see a few seconds of my wave; how do I know
                          if the wave is fully filmed?
                        </h3>
                        <p>
                          Our previews are all 4 seconds long. As long as you
                          surf within the recording area, the full video of your
                          wave will be available for download.
                        </p>

                        <h3>
                          Is the quality of the final video better than the
                          preview?
                        </h3>
                        <p>
                          Yes, the quality of the downloaded video is higher
                          than the preview.
                        </p>

                        <h3>
                          Do i need to select all the waves I want to download
                          before checkout?
                        </h3>
                        <p>
                          Yes, select all your waves before reaching checkout;
                          you cannot add more later.
                        </p>

                        <h3>
                          I surfed a few days ago and haven’t downloaded my
                          videos yet. can i still purchase them?
                        </h3>
                        <p>
                          Yes, but hurry up. All unclaimed videos are deleted 7
                          days after recording.
                        </p>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}

              {inBrazilRegion ? (
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography>
                      <div className="topContent">
                        <img src={faq3} alt="Tab 3" />
                        <h2>preços</h2>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="faqunderContent">
                        {!inBrazilRegion && (
                          <>
                            <h3>O QUE É uma onda?</h3>
                            <p>
                              Uma onda é um vídeo de uma onda que você
                              selecionou. Cada onda custa €4,99.
                            </p>
                          </>
                        )}

                        <h3>O que é um passe diário?</h3>
                        <p>
                          Um passe diário permite que você baixe ondas
                          ilimitadas do dia e local que você selecionou.
                        </p>

                        <h3>O que é o Surf Eye Unlimited?</h3>
                        <p>
                          Surf Eye Unlimited oferece 30 dias de vídeos
                          ilimitados de todas as nossas câmeras ativas e
                          futuras. Perfeito para melhorar sua técnica de surfe e
                          nunca perder uma onda!
                        </p>

                        <h3>
                          Tenho um código de desconto. Como faço para
                          resgatá-lo?
                        </h3>
                        <p>
                          Na parte inferior da página de checkout, você
                          encontrará um campo de entrada “Código de desconto”.
                          Insira seu código e seu desconto será aplicado. Se não
                          funcionar, você pode já tê-lo usado ou ele expirou.
                        </p>

                        <h3>Quais são os métodos de pagamento aceitos?</h3>
                        <p>
                          Aceitamos todos os principais cartões de crédito e
                          débito; pagamentos em dinheiro não são aceitos.
                        </p>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography>
                      <div className="topContent">
                        <img src={faq3} alt="Tab 3" />
                        <h2>pricing</h2>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="faqunderContent">
                        <h3>WHAT IS a wave?</h3>
                        <p>
                          A wave is one video of a wave you selected. Each wave
                          costs €4,99.
                        </p>

                        <h3>What is a day pass?</h3>
                        <p>
                          A day pass allows you to download unlimited waves from
                          the day and location you selected.
                        </p>

                        <h3>What is surf eye unlimited?</h3>
                        <p>
                          Surf Eye Unlimited gives you 30 days of unlimited
                          videos from all our active and upcoming cameras.
                          Perfect for improving your surfing technique and never
                          miss a wave!
                        </p>

                        <h3>I have a discount code. how do i redeem it?</h3>
                        <p>
                          At the bottom of the checkout page, you’ll find an
                          “Discount code” input field. Enter your code, and your
                          discount will be applied. If it doesn’t work, you may
                          have already used it or it has expired.
                        </p>

                        <h3>What are accepted payment methods?</h3>
                        <p>
                          We accept all major credit and debit cards; cash
                          payments are not accepted.
                        </p>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>

            {inBrazilRegion ? (
              <div className="faqInformation">
                <h5>
                  Para quaisquer outras questões, sinta-se à vontade para nos
                  contatar em
                </h5>
                <a href="mailto: info@surfeye.video">
                  <span>
                    <img src={Email} alt="mail" />
                  </span>
                  <span>info@surfeye.video</span>
                </a>
                <a href="https://wa.me/351934673643" target="_blank">
                  <span>
                    <img src={whatsapp} alt="call" />
                  </span>
                  <span>+351 934 673 643</span>
                </a>
              </div>
            ) : (
              <div className="faqInformation">
                <h5>For any other questions, feel free to contact us at</h5>
                <a href="mailto: info@surfeye.video">
                  <span>
                    <img src={Email} alt="mail" />
                  </span>
                  <span>info@surfeye.video</span>
                </a>
                <a href="https://wa.me/351934673643" target="_blank">
                  <span>
                    <img src={whatsapp} alt="call" />
                  </span>
                  <span>+351 934 673 643</span>
                </a>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* LocationArea modal */}
      {seeLocationModal ? (
        <LocationArea
          locationDetails={targetLocationModalItem}
          closeHandler={closeHandler}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const regions = [];

export { LandingPage, regions };
