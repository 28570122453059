import styles from "../Session/Checkout/Checkout.module.css";
import { inBrazilRegion } from "../../utils/region";

export const FAQ = ({ handleClick }) => {
  return (
    <>
      <div className={styles.faqModalStart}>
        <div className={styles.background}></div>
        <div className={styles.faqModal}>
          <div className={styles.faqHeading}>
            <h2>FAQ</h2>
            <button
              className={styles.closeFaq}
              onClick={() => handleClick(false)}
            >
              X close
            </button>
          </div>
          {/*TODO: Final faq stuff*/}
          <div className={styles.faqBox}>
            {inBrazilRegion ? (
              <div className={styles.boxText}>
                <h3>GERAL</h3>
                <h4>O que é o Surf Eye?</h4>
                <p>
                  O Surf Eye é uma rede de câmeras de alta resolução que gravam
                  e editam automaticamente todas as ondas surfadas dentro das
                  nossas Áreas de Gravação (confira nossa página inicial para
                  ver os locais exatos).
                </p>
                <h4>Preciso reservar com antecedência?</h4>
                <p>
                  Não, nossas câmeras gravam todos os surfistas,
                  independentemente de terem pago ou não.
                </p>
                <h4>Como faço para ser gravado?</h4>
                <p>
                  Basta surfar em uma de nossas áreas de gravação. Nossas
                  câmeras estão ativas todos os dias; você só precisa lembrar o
                  horário em que sua sessão começou. Acesse nosso site 45
                  minutos após o fim da sessão para selecionar suas ondas a
                  partir de prévias de 4 segundos. Os vídeos completos das ondas
                  estarão disponíveis após a compra.
                </p>
                <h4>Como a câmera sabe quem eu sou?</h4>
                <p>
                  Nossas câmeras não identificam indivíduos. Após sua sessão,
                  acesse nosso site, faça login ou cadastre-se, e selecione e
                  baixe suas ondas.
                </p>
                <h4>Preciso usar algo específico para ser gravado?</h4>
                <p>
                  Não, não é necessário, mas se quiser se destacar, pode usar
                  itens chamativos ou coloridos (lycra, boné etc.). Isso é
                  totalmente opcional.
                </p>

                <h3>Vídeos</h3>
                <h4>
                  Só consigo ver alguns segundos da minha onda; como sei se ela
                  foi filmada por completo?
                </h4>
                <p>
                  Nossas prévias têm duração de 4 segundos. Desde que você tenha
                  surfado dentro da área de gravação, o vídeo completo da sua
                  onda estará disponível para download.
                </p>
                <h4>A qualidade do vídeo final é melhor que a da prévia?</h4>
                <p>
                  Sim, a qualidade do vídeo baixado é um pouco melhor que a da
                  prévia.
                </p>
                <h4>
                  Preciso selecionar todas as ondas que quero baixar antes de
                  finalizar a compra?
                </h4>
                <p>
                  Sim, selecione todas as suas ondas antes do checkout, pois não
                  será possível adicionar mais depois.
                </p>
                <h4>
                  Surfei há alguns dias e ainda não baixei meus vídeos. Posso
                  comprá-los ainda?
                </h4>
                <p>
                  Sim, mas corra! Todos os vídeos não baixados são excluídos 7
                  dias após a gravação.
                </p>
                <h3>Preço</h3>
                <h4>O que é uma onda?</h4>
                <p>Uma onda é um vídeo da onda que você selecionou.</p>
                <h4>O que é um Day Pass?</h4>
                <p>
                  O Day Pass permite que você baixe ondas ilimitadas do dia
                  selecionado. Certifique-se de escolher todas as suas ondas
                  antes de comprar, pois não será possível adicionar mais
                  depois.
                </p>
                <h4>O que é o Surf Eye Unlimited?</h4>
                <p>
                  O Surf Eye Unlimited oferece 30 dias de vídeos ilimitados de
                  todas as nossas câmeras ativas e futuras. Perfeito para
                  aprimorar seu surf e técnica com análise de vídeos.
                </p>
                <h3>Pagamento</h3>
                <h4>Tenho um código de desconto. Como posso usá-lo?</h4>
                <p>
                  Na página de checkout, há um campo para inserir o código de
                  desconto. Digite o código e o desconto será aplicado. Se não
                  funcionar, pode ser que já tenha sido usado ou esteja
                  expirado.
                </p>
                <h4>Quais formas de pagamento são aceitas?</h4>
                <p>
                  Aceitamos todos os principais cartões de crédito e débito. Pagamentos em dinheiro não são aceitos.
                </p>
                <p>
                  <b>Para qualquer outra dúvida, </b> , entre em contato conosco pelo e-mail
                  <b>
                    {" "}
                    <a href="mailto: info@surfeye.video"> info@surfeye.video</a>
                  </b>{" "}
                  ou pelo {" "}
                  <b>
                    {" "}
                    WhatsApp at
                    <a href="https://wa.me/351934673643" target="_blank">
                      {" "}
                      +351 934673643
                    </a>
                    .
                  </b>
                </p>
              </div>
            ) : (
              <div className={styles.boxText}>
                <h3>GENERAL</h3>
                <h4>What is Surf Eye?</h4>
                <p>
                  Surf Eye is a network of high-resolution cameras that
                  automatically record and edit every wave surfed within our
                  Recording Areas (check our landing page for exact locations).
                </p>
                <h4>Do I need to book in advance?</h4>
                <p>
                  No, our cameras record every surfer, whether they've paid or
                  not.
                </p>
                <h4>How do I get recorded?</h4>
                <p>
                  Surf within one of our recording areas. Our cameras are active
                  every day; you just need to remember the time your session
                  started. Visit our website 45 minutes after your session to
                  select your waves from 4-second previews. Full wave videos are
                  available after purchase.
                </p>
                <h4>How does the camera know who I am?</h4>
                <p>
                  Our cameras don't identify individuals. After your session,
                  visit our website, sign up or log in, and select and download
                  your waves.
                </p>
                <h4>Do I need to wear something to be recorded?</h4>
                <p>
                  No, it's not necessary, but if you want to make yourself more
                  recognizable, you can wear distinctive or colored items
                  (lycra, hat, etc.), though it's entirely optional.
                </p>
                <h3>VIDEOS</h3>
                <h4>
                  I can only see a few seconds of my wave; how do I know if the
                  wave is fully filmed?
                </h4>
                <p>
                  Our previews are all 4 seconds long. As long as you surf
                  within the recording area, the full video of your wave will be
                  available for download.
                </p>
                <h4>
                  Is the quality of the final video better than the preview?
                </h4>
                <p>
                  Yes, the quality of the downloaded video is slightly better
                  than the preview.
                </p>
                <h4>
                  Do I need to select all the waves I want to download before
                  checkout?
                </h4>
                <p>
                  Yes, select all your waves before reaching checkout; you
                  cannot add more later.
                </p>
                <h4>
                  I surfed a few days ago and haven't downloaded my videos yet.
                  Can I still purchase them?
                </h4>
                <p>
                  Yes, but hurry up. All unclaimed videos are deleted 7 days
                  after recording.
                </p>
                <h3>PRICING</h3>
                <h4>What is a wave?</h4>
                <p>A wave is one video of a wave you selected.</p>
                <h4>What is a day pass?</h4>
                <p>
                  A day pass lets you download unlimited waves from your
                  selected day. Make sure to select all your waves before
                  purchasing, as you cannot add more later.
                </p>
                <h4>What is Surf Eye Unlimited?</h4>
                <p>
                  Surf Eye Unlimited grants you 30 days of unlimited videos from
                  all our active and upcoming cameras. Perfect for improving
                  your surfing and technique with video analysis.
                </p>
                <h3>PAYMENT</h3>
                <h4>I have a discount code. How do I redeem it?</h4>
                <p>
                  At the bottom of the checkout page, you'll find an "Discount
                  code" input field. Enter your code, and your discount will be
                  applied. If it doesn't work, you may have already used it or
                  it has expired.
                </p>
                <h4>What are accepted payment methods?</h4>
                <p>
                  We accept all major credit and debit cards; cash payments are
                  not accepted.
                </p>
                <p>
                  <b>For any other questions,</b> feel free to contact us at{" "}
                  <b>
                    {" "}
                    <a href="mailto: info@surfeye.video"> info@surfeye.video</a>
                  </b>{" "}
                  or on{" "}
                  <b>
                    {" "}
                    WhatsApp at
                    <a href="https://wa.me/351934673643" target="_blank">
                      {" "}
                      +351 934673643
                    </a>
                    .
                  </b>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
