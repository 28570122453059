import React from "react";
import { UserIcon } from "../../../assets/SitePage/icons";
import { inBrazilRegion } from "../../../utils/region";

export const navbarLocations = inBrazilRegion
  ? [
      {
        id: "br-praia-do-tombo",
        href: "/beachcam/br-praia-do-tombo",
        label: "Praia do Tombo - Guarujá",
      },
      {
        id: "br-maresias",
        href: "/beachcam/br-maresias",
        label: " Maresias - São Sebastiao",
      },
      {
        id: "br-itamambuca",
        href: "/beachcam/br-itamambuca",
        label: "Itamambuca  - Ubatuba",
      },
      {
        id: "br-vermelha-do-Norte",
        href: "/beachcam/br-vermelha-do-Norte",
        label: "Vermelha do Norte - Ubatuba",
      },
    ]
  : [
      {
        id: "peniche-bar-do-bruno",
        href: "/beachcam/bar-do-bruno",
        label: "Bar do Bruno - Baleal",
      },
      {
        id: "cascais-carcavelos-tunnel",
        href: "/beachcam/carcavelos-tunnel",
        label: "Tunnel - Carcavelos",
      },
      {
        id: "costa-da-caprica-tarquinio",
        href: "/beachcam/caparica-tarquinio",
        label: "Tarquínio - Caparica",
      },
      {
        id: "costa-da-caprica-nova-praia",
        href: "/beachcam/nova-praia",
        label: "Nova Praia - Caparica",
      },
      {
        id: "costa-da-caprica-fonte-de-telha",
        href: "/beachcam/caparica-fonte-da-telha",
        label: "Swara - Fonte da Telha",
      },
      {
        id: "aljezur-arrifana",
        href: "/beachcam/aljezur-arrifana",
        label: "Arrifana - Aljezur",
      },
    ];

export const navbarPools = [
  {
    id: "our-wavepools",
    href: "https://wavepools.surfeye.video/",
    label: inBrazilRegion ? "Piscinas de ondas" : "All Our Wavepools",
  },
  {
    id: "ec1-alaia-bay-sion-ch",
    href: "https://alaiabay.surfeye.video/",
    label: "Alaïa Bay",
  },
  {
    id: "citywave-vilnius-lt",
    href: "https://citywavevilnius.surfeye.video/",
    label: "Citywave Vilnius",
  },
  {
    id: "de-jochen-schweizer-munich",
    href: "https://jsa.surfeye.video/",
    label: "Jochen Schweizer Arena",
  },
  {
    id: "br-praia-da-grama",
    href: "https://www.instagram.com/fazendadagrama/",
    label: "Praia da Grama",
  },
  {
    id: "rif010-rotterdam-nl",
    href: "https://rif010.surfeye.video/",
    label: "RiF010",
  },
  {
    id: "skudin-surf-ad-nyc-us",
    href: "https://skudinsurfad.surfeye.video/",
    label: "Skudin Surf AD",
  },
  {
    id: "surf-centre-curitiba-br",
    href: "https://surfcenter.net/",
    label: "Surf Centre",
  },
  {
    id: "de-surf-langenfeld",
    href: "https://surf-langenfeld.surfeye.video/",
    label: "Surf Langenfeld",
  },
  {
    id: "ec-urbansurf-zurich-ch",
    href: "https://urbansurf.ch/de/",
    label: "Urbansurf Zürich",
  },
  {
    id: "wellenwerk-berlin-de",
    href: "https://wellenwerk.surfeye.video/",
    label: "Wellenwerk",
  },
  {
    id: "wakeparadise-milan-it",
    href: "https://wakeparadise.surfeye.video/",
    label: "Wakeparadise",
  }
];

export const navbarRoutes = [
  // {
  //   id: "how-it-works",
  //   href: "/?section=howitworks",
  //   label: "How it works",
  // },
  // {
  //   id: "highlight",
  //   href: "/?section=highlight",
  //   label: "Highlights",
  // },
  {
    id: "locations",
    label: inBrazilRegion ? "Locais" : "Locations",
    children: navbarLocations,
  },
  // {
  //   id: "price",
  //   href: "/?section=price",
  //   label: "Pricing",
  // },
  {
    id: "profile",
    label: <UserIcon />,
    children: [
      {
        id: "login",
        href: "/?section=login",
        label: inBrazilRegion ? "Cadastre-se" : "Login",
      },
      {
        id: "register",
        href: "/?section=register",
        label: inBrazilRegion ? "Registrar" : "Register",
      },
    ],
  },
];
